import { Pagination } from '@mui/material';
import {
  MobileContainer,
  MobileHeader,
  MobileOrderCard,
  MobileOrderFilter,
  NoResult,
} from 'components';
import { useFilters } from 'hooks';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { currentUser } from 'store';
import styles from './new-orders.module.scss';

const NewOrders = () => {
  const {
    orders,
    resetTempFilters,
    clearTempFilters,
    pageDetail,
    changePage,
    loading,
    totalPage,
  } = useFilters();

  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

  const [user] = useAtom(currentUser);

  const operationAllowed = useMemo(() => {
    const operationAllowedAttribute = user?.custom_attributes?.find(
      ({ attribute_code }) => attribute_code === 'operation_allowed'
    );

    return operationAllowedAttribute?.value === '1';
  }, [user]);

  const headerRightSideAction = () => {
    if (!isFilterOpen) {
      resetTempFilters();
      setFilterOpen(true);
    } else {
      clearTempFilters();
    }
  };

  return (
    <>
      <MobileHeader
        rightIconAction={() => {
          headerRightSideAction();
        }}
        rightIconType={isFilterOpen ? 'clear' : 'filter'}
        title={isFilterOpen ? 'Filtering' : 'New Orders'}
        closeButton={isFilterOpen}
        closeButtonAction={() => setFilterOpen(false)}
      />
      <MobileContainer>
        {!isFilterOpen && orders?.length > 0 && (
          <div className={styles.ordersContainer}>
            {orders.map(order => {
              return (
                <MobileOrderCard
                  key={order.order_id}
                  {...order}
                  operationAllowed={operationAllowed}
                />
              );
            })}
          </div>
        )}

        {!isFilterOpen && orders?.length === 0 && !loading && (
          <NoResult title="Orders not found" />
        )}

        {isFilterOpen && (
          <MobileOrderFilter
            withoutQuickFilters
            setFilterOpen={setFilterOpen}
          />
        )}
        {pageDetail?.currentPage && !isFilterOpen && totalPage > 1 && (
          <div className={styles.pagination}>
            <Pagination
              disabled={loading}
              size="medium"
              onChange={(_, page) => {
                window.scrollTo(0, 0);
                changePage(page);
              }}
              count={Math.ceil(pageDetail.totalCount / 50)}
              page={pageDetail.currentPage}
            />
          </div>
        )}
      </MobileContainer>
    </>
  );
};

export default NewOrders;
