import { Box, Typography } from '@mui/material';
import { getOrderDetail, shipOrderRequest } from 'http-requests';
import { IOrders } from 'models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, InnerLoadingOverlay } from 'components';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {
  deliveryDateTimeFormatter,
  getStatusColorForOrderStatus,
} from 'lib/utils';
import colors from 'styles/variables.scss';
import { useAtomValue, useSetAtom } from 'jotai';
import { alerts, contentLoading, currentUser } from 'store';
import ImageModal from 'components/modals/image-modal';
import styles from './order-detail.module.scss';
import OrderItem from './order-item/order-item';
import DeliveryCard from './delivery-information/delivery-card';
import ShipPopup from './ship-popup/ship-popup';
import { printGiftMessage, printPage } from './print-helper';

const OrderDetail: FC = () => {
  const { orderId } = useParams();
  const setLoading = useSetAtom(contentLoading);
  const [orderDetail, setOrderDetail] = useState<IOrders.IOrderDetail>(
    {} as IOrders.IOrderDetail
  );
  const user = useAtomValue(currentUser);

  const operationAllowed = useMemo(() => {
    const operationAllowedAttribute = user?.custom_attributes?.find(
      ({ attribute_code }) => attribute_code === 'operation_allowed'
    );

    return operationAllowedAttribute?.value === '1';
  }, [user]);

  const [shipPopupOpen, setShipButtonOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const alertArray = useSetAtom(alerts);

  const getOrder = async () => {
    try {
      if (orderId) {
        const response = await getOrderDetail({
          orderId: +orderId,
        });

        setOrderDetail(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const shipOrder = async () => {
    try {
      setLoading(true);
      setShipButtonOpen(false);

      const response = await shipOrderRequest({
        vendorOrderId: orderDetail.merchant_order_id,
      });

      if (response) {
        alertArray(prev => [
          ...prev,
          { severity: 'success', title: 'The order status has been changed' },
        ]);

        getOrder();
      }
    } catch (error: any) {
      setLoading(false);
      alertArray(prev => [
        ...prev,
        { severity: 'error', title: error.response?.data.message },
      ]);
    }
  };

  useEffect(() => {
    setLoading(true);
    getOrder();
  }, []);

  useEffect(() => {
    if (orderDetail?.extension_attributes?.gift_message?.font_family) {
      const css = `
        @import url('https://fonts.googleapis.com/css2?family=${orderDetail?.extension_attributes?.gift_message?.font_family.replace(
          /\s+/g,
          '+'
        )}');
        `;
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.appendChild(document.createTextNode(css));

      head.appendChild(style);
    }
  }, [orderDetail]);

  return (
    <div className={styles.orderDetailContainer}>
      <ImageModal
        open={!!imageUrl}
        onClose={() => setImageUrl('')}
        imageUrl={imageUrl}
      />

      <InnerLoadingOverlay />
      <Box
        sx={{ display: 'flex', gap: 1, alignSelf: 'flex-end', float: 'right' }}
      >
        {orderDetail?.extension_attributes?.can_ship && (
          <Button
            onClick={() => setShipButtonOpen(true)}
            startIcon={<LocalShippingOutlinedIcon />}
            variant="contained"
            color="success"
            text="SHIP"
          />
        )}
        <Button
          onClick={() => printPage(orderDetail, user.name, operationAllowed)}
          variant="contained"
          color="info"
          text="Print Order"
        />
      </Box>
      <Typography fontSize="1.375rem" variant="h6">
        {orderDetail.order_increment_id}
      </Typography>
      <Typography fontWeight="normal" color={colors.black50} fontSize="h6">
        {`Order Date: ${deliveryDateTimeFormatter(orderDetail.created_at)} · `}
        <Typography display="inline">
          Order Status:{' '}
          <Typography
            color={getStatusColorForOrderStatus(orderDetail.status)}
            display="inline"
          >
            {orderDetail.status}
          </Typography>
        </Typography>
      </Typography>
      <div className={styles.productsContainer}>
        {orderDetail?.items?.map(order => (
          <OrderItem
            key={order.sku}
            getOrder={getOrder}
            grandTotal={order?.row_total?.toString()}
            orderStatus={orderDetail.status}
            currency={orderDetail.order_currency_code}
            canShip={orderDetail?.extension_attributes?.can_ship}
            onClickImage={imageUrl => setImageUrl(imageUrl)}
            {...order}
          />
        ))}
      </div>
      <div className={styles.deliveryAndGiftMessageContainer}>
        <DeliveryCard {...orderDetail} operationAllowed={operationAllowed} />
        <div className={styles.giftMessageContainer}>
          <Typography variant="body2" color={colors.black50}>
            Gift Message
          </Typography>
          <Typography
            component="div"
            sx={{ height: '100%' }}
            fontFamily={
              orderDetail?.extension_attributes?.gift_message?.font_family ||
              undefined
            }
            variant="body2"
          >
            {orderDetail?.extension_attributes?.gift_message?.message}

            {orderDetail?.extension_attributes?.gift_message?.qr_url_image && (
              <Typography
                sx={{ mt: 1 }}
                variant="body2"
                color={colors.navyBlue}
              >
                This gift message contains a QR code
              </Typography>
            )}
          </Typography>
          {(orderDetail?.extension_attributes?.gift_message?.message ||
            orderDetail?.extension_attributes?.gift_message?.qr_url_image) && (
            <div style={{ alignSelf: 'flex-end' }}>
              <Button
                onClick={() => printGiftMessage(orderDetail)}
                startIcon={<LocalPrintshopOutlinedIcon />}
                variant="contained"
                color="success"
                text="Print Gift Message"
              />
            </div>
          )}
        </div>
      </div>
      <ShipPopup
        ship={shipOrder}
        open={shipPopupOpen}
        setOpen={setShipButtonOpen}
      />
    </div>
  );
};

export default OrderDetail;
