import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button } from 'components';
import { pathNames } from 'hooks/useRouter';
import { getDeliveries } from 'http-requests';
import { IOrders } from 'models';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai/index';
import { currentUser } from 'store';
import styles from './deliveries.module.scss';

const Deliveries = () => {
  const [orders, setOrders] = useState<Array<IOrders.IOrder>>([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState<'today' | 'tomorrow'>('today');

  const user = useAtomValue(currentUser);

  const getDeliveriesData = async () => {
    const response = await getDeliveries(date);
    setLoading(false);
    setOrders(response);
  };

  const columns: Array<GridColDef> = [
    { field: 'order_increment_id', headerName: 'Order ID', flex: 0.6 },
    { field: 'created_at', headerName: 'Purchase Date', flex: 0.5 },
    // {
    //   field: 'grandTotal',
    //   headerName: 'Grand Total',
    //   valueGetter(params) {
    //     return params.row.grand_total;
    //   },
    //   flex: 0.3,
    // },
    {
      field: 'cost_price',
      headerName: 'Cost Price',
      renderCell(params: any) {
        return params.row.items.reduce(
          (acc: number, { cost = 0 }) => acc + cost,
          0
        );
      },
      flex: 0.3,
    },
    {
      field: 'delivery_city',
      headerName: 'Delivery City',
      renderCell(params: any) {
        return params.row.extension_attributes.delivery_city;
      },
      flex: 0.3,
    },
    {
      field: 'delivery_date',
      headerName: 'Delivery Date',
      renderCell(params: any) {
        return params.row.extension_attributes.delivery_date;
      },
      flex: 0.3,
    },
    {
      field:
        user?.custom_attributes?.find(
          ({ attribute_code }) => attribute_code === 'operation_allowed'
        )?.value === '1'
          ? 'delivery_time'
          : 'pickup_time',
      renderCell(params: any) {
        return user?.custom_attributes?.find(
          ({ attribute_code }) => attribute_code === 'operation_allowed'
        )?.value === '1'
          ? params.row.extension_attributes.delivery_time
          : params.row.extension_attributes.pickup_time;
      },
      headerName:
        user?.custom_attributes?.find(
          ({ attribute_code }) => attribute_code === 'operation_allowed'
        )?.value === '1'
          ? 'Delivery Time'
          : 'Pick Up Time',
      flex: 0.3,
    },
    { field: 'status', headerName: 'Status', flex: 0.4 },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              window.open(
                pathNames.order.replace(
                  ':orderId',
                  params.row.merchant_order_id.toString()
                ),
                '_blank'
              );
            }}
            text="View"
            color="info"
            style={{ marginLeft: -10 }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getDeliveriesData();
  }, [date]);

  return (
    <div className={styles.deliveriesContainer}>
      <div className={styles.tabContainer}>
        <div
          onClick={() => setDate('today')}
          className={`${styles.tabItemContainer} ${
            date === 'today' && styles.active
          }`}
        >
          <Typography>Today</Typography>
        </div>
        <div
          onClick={() => setDate('tomorrow')}
          className={`${styles.tabItemContainer} ${
            date === 'tomorrow' && styles.active
          }`}
        >
          <Typography>Tomorrow</Typography>
        </div>
      </div>

      <DataGrid
        hideFooter
        sx={{
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        getRowId={(row: IOrders.IOrder) => row.order_id}
        loading={loading}
        rows={loading ? [] : orders}
        columns={columns}
        rowCount={orders.length}
        localeText={{
          noRowsLabel: `No deliveries ${date}`,
        }}
      />
    </div>
  );
};

export default Deliveries;
