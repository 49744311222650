import { FC } from 'react';
import styles from './indicator.module.scss';

interface IIndicator {
  activeIndex: number;
  productsLength: number;
}

const Indicator: FC<IIndicator> = ({ activeIndex, productsLength }) => {
  return (
    <div className={styles.indicatorContainer}>
      {Array.from(new Array(productsLength)).map((item, index) => {
        return (
          <div
            key={index.toString()}
            className={`${styles.indicator} ${
              activeIndex === index ? styles.active : null
            }`}
          />
        );
      })}
    </div>
  );
};

export default Indicator;
