import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { IInventory } from 'models';
import { Button } from 'components';
import { ApprovalStatuses, StockStatuses } from 'lib/enums';
import styles from './filter.module.scss';

interface IFilter extends IInventory.IFilter {
  setOpenFilter: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  addToFilter: (key: string, value: string) => void;
  applyFilter: () => void;
  // eslint-disable-next-line no-unused-vars
  clearFilter: (apply?: boolean) => void;
}

const Filter: FC<IFilter> = ({
  setOpenFilter,
  addToFilter,
  applyFilter,
  clearFilter,
  ...restProps
}) => {
  const largeInput: SxProps = {
    width: 300,
  };

  const { filters } = restProps;

  return (
    <FormControl className={styles.filterContainer}>
      <Typography variant="h5" fontWeight="bold">
        Filters
      </Typography>

      <div className={styles.rowContainer}>
        <TextField
          defaultValue=""
          value={filters?.name || ''}
          onChange={({ target }) => addToFilter('name', target.value)}
          sx={{ ...largeInput }}
          label="Name"
        />
        <TextField
          defaultValue=""
          value={filters?.sku || ''}
          onChange={({ target }) => addToFilter('sku', target.value)}
          sx={{ ...largeInput }}
          label="SKU"
        />

        <FormControl sx={{ ...largeInput }}>
          <InputLabel id="approval-status-label">Approval Status</InputLabel>
          <Select
            labelId="approval-status-label"
            id="approval-status-select"
            variant="outlined"
            value={filters?.approval || ''}
            onChange={({ target: { value } }) =>
              addToFilter('approval', value.toString())
            }
            label="Approval Status"
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          >
            <MenuItem value={ApprovalStatuses.NOT_SUBMITTED}>
              Not Submitted
            </MenuItem>
            <MenuItem value={ApprovalStatuses.PENDING_NEW}>
              Pending New
            </MenuItem>
            <MenuItem value={ApprovalStatuses.APPROVED}>Approved</MenuItem>
            <MenuItem value={ApprovalStatuses.UNAPPROVED}>Unapproved</MenuItem>
            <MenuItem value={ApprovalStatuses.PENDING_UPDATE}>
              Pending Update
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ ...largeInput }}>
          <InputLabel id="stock-status-label">Stock Status</InputLabel>
          <Select
            label="Stock Status"
            labelId="stock-status-label"
            id="stock-status-select"
            value={filters?.stock_status || ''}
            onChange={({ target: { value } }) =>
              addToFilter('stock_status', value.toString())
            }
          >
            <MenuItem value={StockStatuses.OUT_OF_STOCK}>Out of Stock</MenuItem>
            <MenuItem value={StockStatuses.IN_STOCK}>In Stock</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={styles.rowContainer}>
        <TextField
          defaultValue=""
          type="number"
          value={filters?.stock_qty__from || ''}
          onChange={({ target }) =>
            addToFilter('stock_qty__from', target.value)
          }
          label="Quantity"
        />
        <Typography>to</Typography>
        <TextField
          defaultValue=""
          type="number"
          value={filters?.stock_qty__to || ''}
          onChange={({ target }) => addToFilter('stock_qty__to', target.value)}
          label="Quantity"
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => clearFilter(true)}
          variant="contained"
          color="inherit"
          text="Clear"
        />
        <Button
          type="submit"
          onClick={() => {
            applyFilter();
            setOpenFilter(false);
          }}
          variant="contained"
          color="info"
          text="Apply Filters"
        />
      </div>
    </FormControl>
  );
};

export default Filter;
