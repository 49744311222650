import { Typography } from '@mui/material';
import { MobileContainer, MobileHeader, MobileOrderCard } from 'components';
import { getDeliveries } from 'http-requests';
import { useAtom } from 'jotai';
import { IOrders } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { contentLoading, currentUser } from 'store';
import styles from './deliveries.module.scss';
import Tabs from './tabs/tabs';

const Deliveries = () => {
  const [loading, setLoading] = useAtom(contentLoading);
  const [user] = useAtom(currentUser);

  const operationAllowed = useMemo(() => {
    const operationAllowedAttribute = user?.custom_attributes?.find(
      ({ attribute_code }) => attribute_code === 'operation_allowed'
    );

    return operationAllowedAttribute?.value === '1';
  }, [user]);

  const [orders, setOrders] = useState<Array<IOrders.IOrder>>([]);
  const [date, setDate] = useState<'today' | 'tomorrow'>('today');

  const getDeliveriesData = async () => {
    const response = await getDeliveries(date);
    setOrders(response);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getDeliveriesData();
  }, [date]);

  return (
    <>
      <MobileHeader borderless title="Deliveries" />

      <MobileContainer>
        <Tabs day={date} setDay={setDate} />

        {!loading && orders?.length === 0 && (
          <div
            style={{
              display: 'flex',
              height: '80%',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography align="center">
              There are no deliveries for {date}.
            </Typography>
          </div>
        )}

        {orders?.length > 0 && (
          <div className={styles.deliveriesContainer}>
            {orders.map(order => {
              return (
                <MobileOrderCard
                  key={order.order_id}
                  operationAllowed={operationAllowed}
                  {...order}
                />
              );
            })}
          </div>
        )}
      </MobileContainer>
    </>
  );
};

export default Deliveries;
