import { FC } from 'react';
import { Button as MButton, ButtonProps } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { buttonTheme } from 'lib/theme';
import { useAtomValue } from 'jotai';
import { contentLoading } from 'store';

interface IButtonProps extends ButtonProps {
  text: string;
}

const Button: FC<IButtonProps> = ({ text, ...rest }) => {
  const loading = useAtomValue(contentLoading);

  return (
    <ThemeProvider theme={buttonTheme}>
      <MButton disabled={loading} {...rest}>
        {text}
      </MButton>
    </ThemeProvider>
  );
};

export default Button;
