import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import colors from 'styles/variables.scss';
import { Button } from 'components';

interface IShipPopup {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  ship: () => Promise<void>;
}

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  backgroundColor: '#fff',
  padding: 24,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
};

const ShipPopup = ({ open, setOpen, ship }: IShipPopup) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Box style={style}>
      <Typography fontWeight={500}>Ship</Typography>
      <Typography sx={{ mt: 2 }} variant="body2" color={colors.black50}>
        The order will be delivered, do you confirm?
      </Typography>

      <div style={{ display: 'flex', gap: 10, marginTop: 36 }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(false)}
          fullWidth
          text="No"
        />
        <Button onClick={ship} fullWidth color="success" text="Yes" />
      </div>
    </Box>
  </Modal>
);

export default ShipPopup;
