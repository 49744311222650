import { IOrders } from 'models';
import { Dispatch, FC, SetStateAction } from 'react';
import HeaderMultipleProduct from './header/header';
import Detail from '../detail/detail';

// Direct React component imports
// eslint-disable-next-line import/no-unresolved
import { Swiper as Sw, SwiperSlide } from 'swiper/react';

// Styles must use direct files imports
import 'swiper/swiper-bundle.css'; // core Swiper

interface ISlider
  extends Omit<IOrders.IOrder, 'delivery_time' | 'pickup_time'> {
  setActiveIndex: Dispatch<SetStateAction<number>>;
}

const Swiper: FC<ISlider> = ({ setActiveIndex, ...rest }) => {
  const {
    items,
    created_at: createdAt,
    order_increment_id: orderId,
    // grand_total: grandTotal,
    status: orderStatus,
  } = rest;
  return (
    <>
      <HeaderMultipleProduct
        date={createdAt}
        orderId={orderId}
        itemLength={items.length}
      />
      <Sw
        onRealIndexChange={element => setActiveIndex(element.activeIndex)}
        controller={{ inverse: true }}
      >
        {items.map(item => {
          return (
            <SwiperSlide key={item.item_id}>
              <Detail
                image={item.image}
                name={item.name}
                // price={grandTotal}
                note=""
                status={orderStatus}
              />
            </SwiperSlide>
          );
        })}
      </Sw>
    </>
  );
};

export default Swiper;
