import { Dialog } from '@mui/material';
import { cdnUrl } from 'lib/utils';

interface IImageModal {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: () => void;
  imageUrl: string;
}

const Index = ({ open, onClose, imageUrl }: IImageModal) => {
  return (
    <Dialog open={open} onClose={onClose} closeAfterTransition>
      <img src={cdnUrl(imageUrl, 400, 500)} alt={imageUrl} loading="lazy" />
    </Dialog>
  );
};

export default Index;
