export const endpoints = {
  login: 'login',
  userInfo: 'me',
  allOrder: 'orders',
  newOrders: 'V2/orders/new',
  ongoing: 'order-items/ongoing',
  products: 'products',
  productDetail: 'products/',
  updateStock: (id: string | number, qty: number) =>
    `products/${id}/inventory/${qty}`,
  orderDetail: 'orders/',
  acceptOrder: 'item/accept/',
  rejectOrder: 'item/reject/',
  csv: 'orders/export',
  updateProfile: 'me/update',
  deliveries: 'orders/deliveries/',
  completeOrder: 'item/complete/',
  ship: (id: any) => `orders/${id}/shipment`,
  registerDevice: 'device/register/',
  logoutDevice: 'logout/',
};
