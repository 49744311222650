import { FC, useState } from 'react';
import {
  MobileContainer,
  MobileHeader,
  MobileSearchBar,
  NoResult,
} from 'components';
import { pathNames } from 'hooks/useRouter';
import { useNavigate } from 'react-router-dom';
import { useInventoryFilters } from 'hooks';
import { Pagination } from '@mui/material';
import ListItem from './components/list-item/list-item';
import Filter from './components/filter/filter';
import styles from './inventory.module.scss';

const Inventory: FC = () => {
  const navigate = useNavigate();
  const {
    products,
    addToTempFilters,
    applyTempFilters,
    tempFilters,
    resetTempFilters,
    clearTempFilters,
    pageDetail,
    totalPage,
    changePage,
    loading,
    addToTempFiltersForMobileSearch,
  } = useInventoryFilters();

  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

  const headerRightSideAction = () => {
    if (!isFilterOpen) {
      resetTempFilters();
      setFilterOpen(true);
    } else {
      clearTempFilters();
    }
  };

  const getDetail = (id: number) => {
    navigate({
      pathname: `${pathNames.inventoryDetail.replace(
        ':productId',
        id.toString()
      )}`,
    });
  };

  return (
    <>
      <MobileHeader
        backPagePath={pathNames.account}
        backButton={!isFilterOpen}
        rightIconAction={() => {
          headerRightSideAction();
        }}
        rightIconType={isFilterOpen ? 'clear' : 'filter'}
        title={isFilterOpen ? 'Filtering' : 'Inventory'}
        closeButton={isFilterOpen}
        closeButtonAction={() => setFilterOpen(false)}
      />
      {!isFilterOpen && (
        <MobileSearchBar
          value={tempFilters.filters?.q || ''}
          handleChange={value => addToTempFiltersForMobileSearch(value)}
          action={() => applyTempFilters(true)}
        />
      )}
      <MobileContainer withHeaderSearchBar={!isFilterOpen} withoutBottomTab>
        {!loading && products?.length === 0 && !isFilterOpen && (
          <NoResult title="Products not found" />
        )}
        {!isFilterOpen && products?.length > 0 && (
          <div className={styles.inventoryContainer}>
            {products?.map(product => {
              return (
                <ListItem
                  key={product.sku}
                  {...product}
                  getDetail={getDetail}
                />
              );
            })}
          </div>
        )}
        {isFilterOpen && (
          <Filter
            {...tempFilters}
            addToFilter={addToTempFilters}
            setOpenFilter={setFilterOpen}
            clearFilter={clearTempFilters}
            applyFilter={applyTempFilters}
          />
        )}
        {pageDetail?.currentPage && !isFilterOpen && totalPage > 1 && (
          <div className={styles.pagination}>
            <Pagination
              disabled={loading}
              size="large"
              onChange={(_, page) => {
                window.scrollTo(0, 0);
                changePage(page);
              }}
              count={totalPage}
              page={pageDetail.currentPage}
            />
          </div>
        )}
        <div style={{ height: 20 }} />
      </MobileContainer>
    </>
  );
};

export default Inventory;
