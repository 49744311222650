import BottomTab from 'components/bottom-tab/bottom-tab';

import { PropsWithChildren, FC, useEffect, useState } from 'react';
import colors from 'styles/variables.scss';

import styles from './mobile-container.module.scss';

const HEADER_HEIGHT = 60;
const BOTTOM_TAB_HEIGHT = 56;
const WITH_SEARCH_BAR_HEADER_HEIGHT = HEADER_HEIGHT * 2;

interface IMobileContainer extends PropsWithChildren {
  withoutBottomTab?: boolean;
  withHeaderSearchBar?: boolean;
  whiteBackground?: boolean;
}

const MobileContainer: FC<IMobileContainer> = ({
  children,
  withoutBottomTab,
  withHeaderSearchBar,
  whiteBackground,
}) => {
  const [height, setHeight] = useState(
    window.innerHeight -
      HEADER_HEIGHT -
      (withoutBottomTab ? 0 : BOTTOM_TAB_HEIGHT)
  );

  const handleResize = () => {
    setHeight(
      window.innerHeight -
        HEADER_HEIGHT -
        (withoutBottomTab ? 0 : BOTTOM_TAB_HEIGHT)
    );
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        height,
        overflowY: 'auto',
        marginTop: withHeaderSearchBar
          ? WITH_SEARCH_BAR_HEADER_HEIGHT
          : HEADER_HEIGHT,
        paddingBottom: BOTTOM_TAB_HEIGHT,
        backgroundColor: whiteBackground
          ? colors.white
          : colors.mobileBackgroundColor,
      }}
      className={styles.mobileContainer}
    >
      {children}

      <div style={{ height: BOTTOM_TAB_HEIGHT }} />
      {!withoutBottomTab && <BottomTab />}
    </div>
  );
};

export default MobileContainer;
