import { FC, useState } from 'react';
import { IOrders } from 'models';
import { useNavigate } from 'react-router-dom';
import { pathNames } from 'hooks/useRouter';
import styles from './mobile-order-card.module.scss';
import Swiper from './swiper/swiper';
import Indicator from './indicator/indicator';
import Detail from './detail/detail';
import Footer from './footer/footer';

type IMobileOrderCard = IOrders.IOrder & { operationAllowed: boolean };

const MobileOrderCard: FC<IMobileOrderCard> = ({ ...props }) => {
  const { items, operationAllowed } = props;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const navigate = useNavigate();

  const getOrderDetail = () => {
    navigate(
      pathNames.order.replace(':orderId', props.merchant_order_id.toString())
    );
  };

  return (
    <div
      onClick={() => getOrderDetail()}
      className={styles.mobileOrderContainer}
    >
      {items.length > 1 && (
        <>
          <Swiper {...props} setActiveIndex={setActiveIndex} />
          <Indicator productsLength={items.length} activeIndex={activeIndex} />
        </>
      )}
      {items.length === 1 &&
        items.map(item => {
          return (
            <Detail
              image={item.image}
              key={item.item_id}
              name={item.name}
              orderId={props.order_increment_id}
              date={props.created_at}
              // price={props.grand_total}
              status={props.status}
            />
          );
        })}

      <Footer {...props} operationAllowed={operationAllowed} />
    </div>
  );
};

export default MobileOrderCard;
