import { ScreenSizes, ScreenType } from 'lib/enums';
import { useEffect, useState } from 'react';

const initialWidth = window.innerWidth;
const isMobile = initialWidth <= ScreenSizes.TABLET;

const useWindowResize = (): ScreenType | null => {
  const [screenType, setScreenType] = useState<ScreenType | null>(
    isMobile ? ScreenType.TABLET : ScreenType.DESKTOP
  );

  const handleResize = () => {
    const width = window.innerWidth;

    if (width <= ScreenSizes.TABLET) {
      setScreenType(ScreenType.TABLET);
    } else {
      setScreenType(ScreenType.DESKTOP);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenType;
};

export default useWindowResize;
