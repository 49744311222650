import { IconButton, Typography } from '@mui/material';
import { FC } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './custom-pagination.module.scss';

interface ICustomPagination {
  currentPage: number;
  totalCount: number;
  // eslint-disable-next-line no-unused-vars
  change: (page: number) => void;
}

const CustomPagination: FC<ICustomPagination> = ({
  currentPage,
  totalCount,
  change,
}) => {
  const hasNextPage = currentPage * 50 <= totalCount;
  const currentPageCount =
    totalCount < 50 ? totalCount : !hasNextPage ? totalCount : 50 * currentPage;

  return (
    <div className={styles.customPagination}>
      <Typography>
        {currentPage * 50 - 50 + 1}-{currentPageCount} of {totalCount}
      </Typography>
      <IconButton
        onClick={() => change(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        disabled={!hasNextPage}
        onClick={() => change(currentPage + 1)}
      >
        <ChevronRightIcon />
      </IconButton>
    </div>
  );
};

export default CustomPagination;
