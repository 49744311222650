import { Typography } from '@mui/material';
import { FC } from 'react';

import styles from './error.module.scss';

interface IError {}

const Error: FC<IError> = () => {
  return (
    <div className={styles.errorContainer}>
      <Typography>
        <strong>404</strong> | Error
      </Typography>
    </div>
  );
};

export default Error;
