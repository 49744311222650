import { FC, useState } from 'react';
import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { Button } from 'components';
import { alerts, userToken } from 'store';
import { useNavigate } from 'react-router-dom';
import { pathNames } from 'hooks/useRouter';
import { loginRequest, registerDevice } from 'http-requests';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { IAuth } from 'models';
import { useSetAtom } from 'jotai';
import { OrderStatus } from 'lib/enums';
import styles from './login.module.scss';
import Logo from '../../components/Logo/Logo';

const loginValidation = yup.object({
  username: yup.string().trim().required('Invalid e-mail'),
  password: yup
    .string()
    .required('Password can not be empty')
    .min(6, 'The password is too short'),
});

const initialValues: IAuth.ILoginRequest = {
  username: '',
  password: '',
};

const Login: FC = () => {
  const navigate = useNavigate();
  const updateToken = useSetAtom(userToken);

  const alertArray = useSetAtom(alerts);
  const [remember, setRemember] = useState(true);

  const handleRequest = async (values: IAuth.ILoginRequest) => {
    try {
      const response = await loginRequest(values);

      updateToken(response);
      navigate(`${pathNames.allOrders}?status=${OrderStatus.PROCESSING}`);

      if (window.deviceToken) {
        await registerDevice();
      }
    } catch (error: any) {
      alertArray(prev => [
        ...prev,
        { severity: 'error', title: error.response?.data.message },
      ]);
    }
  };

  const formik = useFormik({
    onSubmit: handleRequest,
    initialValues,
    validationSchema: loginValidation,
  });

  return (
    <div className={styles.loginContainer}>
      <Stack direction="column" justifyContent="center" p={2} width={450}>
        <Logo className={styles.logo} />

        <form id="loginForm" onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="Username *"
            autoCapitalize="none"
            name="username"
            autoComplete="email"
            type="email"
            onBlur={formik.handleBlur('username')}
            defaultValue={formik.values.username}
            onChange={formik.handleChange('username')}
            error={formik.touched.username && !!formik.errors?.username}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            placeholder="Password *"
            type="password"
            name="password"
            autoComplete="password"
            defaultValue={formik.values.password}
            onBlur={formik.handleBlur('password')}
            onChange={formik.handleChange('password')}
            error={formik.touched.password && !!formik.errors?.password}
            helperText={formik.touched.password && formik.errors.password}
          />
          <FormControlLabel
            onClick={() => setRemember(prev => !prev)}
            control={<Checkbox checked={remember} />}
            label="Remember me"
          />
          <Button
            disabled={
              Object.keys(formik.errors).length > 0 ||
              !formik.values.username ||
              !formik.values.password
            }
            form="loginForm"
            type="submit"
            variant="contained"
            color="primary"
            text="Sign in"
          />
        </form>
      </Stack>
    </div>
  );
};

export default Login;
