import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import colors from 'styles/variables.scss';
import { Button } from 'components';
import { ApprovalStatuses, StockStatuses } from 'lib/enums';
import { IInventory } from 'models';
import styles from './filter.module.scss';

interface IFilter extends IInventory.IFilter {
  setOpenFilter: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  addToFilter: (key: string, value: string) => void;
  // eslint-disable-next-line no-unused-vars
  applyFilter: (replace?: boolean) => void;
  clearFilter: () => void;
}

const Filter: FC<IFilter> = ({
  setOpenFilter,
  addToFilter,
  applyFilter,
  clearFilter,
  ...restProps
}) => {
  const { filters } = restProps;

  return (
    <div className={styles.filterContainer}>
      <TextField
        label="NAME"
        defaultValue=""
        value={filters?.name || ''}
        onChange={({ target }) => addToFilter('name', target.value)}
      />
      <TextField
        sx={{ mt: 2 }}
        label="SKU"
        defaultValue=""
        value={filters?.sku || ''}
        onChange={({ target }) => addToFilter('sku', target.value)}
      />
      <div className={styles.divider} />
      <FormControl fullWidth>
        <InputLabel id="approval-status-label">Approval Status</InputLabel>
        <Select
          labelId="approval-status-label"
          id="approval-status-select"
          variant="outlined"
          value={filters?.approval || ''}
          onChange={({ target: { value } }) =>
            addToFilter('approval', value.toString())
          }
          label="Approval Status"
          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        >
          <MenuItem value={ApprovalStatuses.NOT_SUBMITTED}>
            Not Submitted
          </MenuItem>
          <MenuItem value={ApprovalStatuses.PENDING_NEW}>Pending New</MenuItem>
          <MenuItem value={ApprovalStatuses.APPROVED}>Approved</MenuItem>
          <MenuItem value={ApprovalStatuses.UNAPPROVED}>Unapproved</MenuItem>
          <MenuItem value={ApprovalStatuses.PENDING_UPDATE}>
            Pending Update
          </MenuItem>
        </Select>
      </FormControl>
      <div className={styles.divider} />
      <FormControl fullWidth>
        <InputLabel id="stock-status-label">Stock Status</InputLabel>
        <Select
          label="Stock Status"
          labelId="stock-status-label"
          id="stock-status-select"
          value={filters?.stock_status || ''}
          onChange={({ target }) =>
            addToFilter('stock_status', target.value.toString())
          }
        >
          <MenuItem value={StockStatuses.OUT_OF_STOCK}>Out of Stock</MenuItem>
          <MenuItem value={StockStatuses.IN_STOCK}>In Stock</MenuItem>
        </Select>
      </FormControl>
      <div className={styles.divider} />
      <Typography color={colors.black50} variant="body2">
        Quantity
      </Typography>
      <div className={styles.rowArea}>
        <TextField
          fullWidth
          defaultValue=""
          type="number"
          value={filters?.stock_qty__from || ''}
          onChange={({ target }) =>
            addToFilter('stock_qty__from', target.value)
          }
          label="Minimum"
        />
        <TextField
          fullWidth
          defaultValue=""
          type="number"
          value={filters?.stock_qty__to || ''}
          onChange={({ target }) => addToFilter('stock_qty__to', target.value)}
          label="Maximum"
        />
      </div>

      <div className={styles.applyContainer}>
        <Button
          onClick={() => {
            applyFilter();
            setOpenFilter(false);
            window.scrollTo(0, 0);
          }}
          fullWidth
          text="apply"
          variant="contained"
        />
      </div>
    </div>
  );
};

export default Filter;
