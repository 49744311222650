import { Typography } from '@mui/material';
import { IPaths, pathNames } from 'hooks/useRouter';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ArrowForwardIos';
import colors from 'styles/variables.scss';
import { removeRegisterDevice } from 'http-requests';

import styles from './page-list.module.scss';

interface IPageList {
  pageName: string;
  path: IPaths;
  icon: React.ReactNode;
}

const PageList: FC<IPageList> = ({ pageName, path, icon }) => {
  const navigate = useNavigate();
  const isLogout = path === pathNames.login;

  const action = async () => {
    if (isLogout) {
      await removeRegisterDevice();

      window.localStorage.removeItem('token');

      if (window?.deviceToken) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            channel: 'badgeClear',
          })
        );
      }
    }

    navigate(path, { replace: true });
  };

  return (
    <div onClick={action} className={styles.pageListContainer}>
      {icon}

      <Typography
        sx={{ color: isLogout ? colors.red : undefined }}
        lineHeight={0}
        className={styles.textArea}
        fontWeight={500}
      >
        {pageName}
      </Typography>

      <ChevronLeftOutlinedIcon
        htmlColor={isLogout ? colors.red : undefined}
        fontSize="small"
      />
    </div>
  );
};

export default PageList;
