import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Button } from 'components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { currentUser, currentUserNeedUpdate } from 'store';
import colors from 'styles/variables.scss';
import { updateUser } from 'http-requests';
import { validateEmail } from 'lib/utils';
import InformationRow from './account-information-row/account-information-row';

import styles from './account.module.scss';

const Account = () => {
  const user = useAtomValue(currentUser);
  const needUpdate = useSetAtom(currentUserNeedUpdate);

  const { order_emails, order_enabled } = useMemo(() => {
    if (!user?.custom_attributes) {
      return {
        order_emails: '',
        order_enabled: false,
      };
    }

    const { order } = JSON.parse(
      user.custom_attributes.find(
        ({ attribute_code }) => attribute_code === 'sales_emails'
      )?.value
    );

    return {
      order_emails: order?.copy_to,
      order_enabled: order?.enabled === 1,
    };
  }, [user]);

  const [orderEmails, setOrderEmails] = useState<string>('');
  const [orderEnabled, setOrderEnabled] = useState<'true' | 'false'>('false');

  useEffect(() => {
    setOrderEmails(order_emails);
    setOrderEnabled(order_enabled.toString() as 'true' | 'false');
  }, [order_emails, order_enabled]);

  const [errorEmail, setErrorEmail] = useState<string | null>(null);

  const noChange =
    orderEmails === order_emails?.toString() &&
    (orderEnabled === 'true') === order_enabled;

  const subTextProps: TypographyProps = {
    variant: 'body2',
    color: colors.black50,
  };

  const saveChanges = async () => {
    const inValidEmail = validateEmail(orderEmails);

    if (inValidEmail) {
      setErrorEmail('Invalid email!');
      return;
    }

    setErrorEmail(null);

    await updateUser({
      merchant: {
        merchant_id: user.merchant_id,
        custom_attributes: [
          {
            attribute_code: 'sales_emails',
            value: JSON.stringify({
              order: {
                enabled: orderEnabled === 'true' ? 1 : 0,
                copy_to: orderEmails,
              },
            }),
          },
        ],
      },
    });

    needUpdate(prev => !prev);
  };

  useEffect(() => {
    if (noChange && errorEmail) {
      setErrorEmail(null);
    }
  }, [noChange, errorEmail]);

  return (
    <div className={styles.accountContainer}>
      <Typography variant="h6">Profile Information</Typography>
      <Typography sx={{ mt: 4 }} {...subTextProps}>
        User Name
      </Typography>
      <Typography sx={{ mt: 1.5 }}>{user?.name || '-'}</Typography>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Typography sx={{ mb: 3 }} {...subTextProps}>
        Account Information
      </Typography>

      <InformationRow
        border
        title="Company Name"
        value={user?.company || '-'}
      />
      <InformationRow
        border
        title="Store Name"
        value={user.store_name || '-'}
      />
      <InformationRow
        border
        title="Email"
        value={user?.extension_attributes?.email || '-'}
      />
      <InformationRow title="Phone" value={user?.telephone || '-'} />

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography {...subTextProps}>Email Settings</Typography>

      <FormControl sx={{ mt: 3 }}>
        <InputLabel id="order_enabled_label_id">Order Enabled</InputLabel>
        <Select
          labelId="order_enabled_label_id"
          value={orderEnabled}
          fullWidth
          label="Order Enabled"
          onChange={({ target: { value } }) =>
            setOrderEnabled(value as 'true' | 'false')
          }
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
      <Typography sx={{ mt: 3 }} fontWeight={500} variant="body1">
        Send orders to my email
      </Typography>
      <Typography sx={{ mt: 0.6 }} {...subTextProps}>
        You can enter multiple email addresses separated by commas.
      </Typography>

      <TextField
        sx={{ mt: 3 }}
        label="Email"
        InputProps={{
          sx: { height: 95, verticalAlign: 'top' },
        }}
        fullWidth
        multiline
        error={!!errorEmail}
        helperText={errorEmail}
        value={orderEmails}
        onChange={({ target: { value } }) => setOrderEmails(value)}
      />

      <Button
        disabled={noChange}
        onClick={() => saveChanges()}
        text="save"
        variant="contained"
        sx={{ mt: 3, mb: 3, alignSelf: 'end' }}
      />
    </div>
  );
};

export default Account;
