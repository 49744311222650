import { Divider, Grid, Link, Typography } from '@mui/material';
import { Button, MobileContainer, MobileHeader } from 'components';
import { useAtomValue } from 'jotai';
import { currentUser } from 'store';
import { pathNames } from 'hooks/useRouter';
import colors from 'styles/variables.scss';
import styles from './help.module.scss';

const Help = () => {
  const user = useAtomValue(currentUser);
  const helpEmail = user?.extension_attributes?.help_email;
  const helpTelephone = user?.extension_attributes?.help_telephone;
  const phoneText = helpTelephone.split(/[\s,]+/) || [];

  return (
    <>
      <MobileHeader title="Help" backButton backPagePath={pathNames.account} />
      <MobileContainer whiteBackground withoutBottomTab>
        <Typography
          align="center"
          fontWeight={500}
          variant="h6"
          sx={{ mt: 3, ml: 2 }}
        >
          We&apos;re always here to help!
        </Typography>
        <Typography
          variant="body2"
          color={colors.black50}
          sx={{ mt: 5.9, ml: 2 }}
        >
          Phone Number
        </Typography>
        <Typography sx={{ mt: 1.5, ml: 2 }}>
          {phoneText.map((text, index) => {
            const isPhone = text.includes('+');

            return (
              <Link
                key={index.toString()}
                href={isPhone ? `tel:${text}` : undefined}
                underline={isPhone ? 'always' : 'none'}
                color="inherit"
              >
                <Typography
                  fontWeight="normal"
                  variant={isPhone ? 'h6' : 'body1'}
                  display="inline"
                >
                  {isPhone ? text : <span>&nbsp;&nbsp;{text}&nbsp;&nbsp;</span>}
                </Typography>
              </Link>
            );
          })}
        </Typography>
        <Divider sx={{ mt: 3, mb: 3 }} />
        <Typography
          variant="body2"
          color={colors.black50}
          sx={{ mt: 5.9, ml: 2 }}
        >
          Email
        </Typography>
        <Typography sx={{ mt: 1.5, ml: 2 }}>{helpEmail}</Typography>
        <div className={styles.footerContainer}>
          <Divider sx={{ mb: 2 }} />
          <Grid sx={{ pl: 2, pr: 2 }}>
            <Link underline="none" color="inherit" href={`tel:${phoneText[0]}`}>
              <Button
                sx={{ pt: 1.6, pb: 1.6 }}
                fullWidth
                text="Call Us"
                variant="contained"
              />
            </Link>
          </Grid>
          <Typography
            align="center"
            sx={{ mt: 0.6 }}
            variant="body2"
            color={colors.black50}
          >
            You can call from 9am to 1am
          </Typography>
        </div>
      </MobileContainer>
    </>
  );
};

export default Help;
