import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Button from 'components/button/button';
import { rejectReasons } from 'lib/constants';
import { CSSProperties, Dispatch, FC, SetStateAction, useState } from 'react';
import colors from 'styles/variables.scss';

interface IRejectPopup {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  reject: (reason: string, detail: string) => Promise<void>;
}

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  backgroundColor: '#fff',
  padding: 24,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
};

const RejectPopup: FC<IRejectPopup> = ({ open, setOpen, reject }) => {
  const [reasonType, setReasonType] = useState('');
  const [comment, setComment] = useState('');
  const [inputError, setInputError] = useState(false);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={style}>
        <Typography fontWeight={500}>Reject the order?</Typography>
        <Typography sx={{ mt: 2 }} variant="body2" color={colors.black50}>
          You are about to reject the order. If you are sure to do that, please
          select a reason.
        </Typography>

        <FormControl sx={{ mt: 4 }}>
          <InputLabel id="reject-reason-label">Select a reason</InputLabel>
          <Select
            value={reasonType}
            onChange={({ target: { value } }) => setReasonType(value)}
            label="Select a reason"
            labelId="reject-reason-label"
          >
            {rejectReasons.map(reason => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={comment}
          error={inputError}
          onChange={({ target: { value } }) => setComment(value)}
          sx={{ mt: 4 }}
          label="Type the reason detail"
          InputProps={{
            sx: { height: 120 },
            inputProps: {
              style: {
                height: '100%',
                paddingTop: 0,
              },
            },
          }}
          fullWidth
          multiline
        />

        <div style={{ display: 'flex', gap: 10, marginTop: 36 }}>
          <Button onClick={() => setOpen(false)} fullWidth text="Cancel" />
          <Button
            onClick={async () => {
              if (reasonType === 'other' && !comment.trim()) {
                setInputError(true);
              }
              await reject(reasonType, comment);
            }}
            fullWidth
            disabled={
              !reasonType.trim() || (reasonType === 'other' && !comment.trim())
            }
            variant="contained"
            color="error"
            text="Reject The Order"
          />
        </div>
      </Box>
    </Modal>
  );
};

export default RejectPopup;
