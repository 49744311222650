import {
  Stack,
  Alert as MuiAlert,
  AlertTitle as MuiAlertTitle,
} from '@mui/material';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { alerts } from 'store';

import styles from './alert.module.scss';

const Alert = () => {
  const [array, setArray] = useAtom(alerts);

  useEffect(() => {
    const interval = setInterval(() => {
      if (array.length > 0) {
        setArray([...array.slice(1)]);
      }
    }, 4000);

    if (array.length === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [array]);

  return (
    <div className={styles.alertContainer}>
      <Stack spacing={2}>
        {array.map((item, index) => (
          <MuiAlert key={index} severity={item.severity}>
            <MuiAlertTitle>{item.title}</MuiAlertTitle>
            {item?.desc}
          </MuiAlert>
        ))}
      </Stack>
    </div>
  );
};

export default Alert;
