import { Typography } from '@mui/material';
import { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAtomValue } from 'jotai';
import { topTabQuickFilters } from 'store';
import { useFilters } from 'hooks';
import { getOrderStatusLabel } from 'lib/utils';

import styles from './top-tab.module.scss';

const TopTab: FC = () => {
  const quickFilters = useAtomValue(topTabQuickFilters);
  const { checkQuickFilterIsInTheFilters, addQuickFiltersToFilters } =
    useFilters(true);

  return (
    <div className={styles.topTabContainer}>
      {quickFilters.map(filter => {
        const isFilterSelected = checkQuickFilterIsInTheFilters(filter);
        return (
          <div
            key={filter}
            onClick={() => addQuickFiltersToFilters(filter)}
            className={`${styles.tabItem} ${
              isFilterSelected ? styles.active : ''
            }`}
          >
            {isFilterSelected && (
              <CheckCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
            )}
            <Typography noWrap variant="body2">
              {getOrderStatusLabel(filter)}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TopTab;
