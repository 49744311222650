import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { pathNames } from 'hooks/useRouter';

const httpRequest = axios.create({
  baseURL: `/api/`,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
});

const requestHandler = async (request: InternalAxiosRequestConfig<any>) => {
  const token = window.localStorage.getItem('token');

  if (request?.headers && token) {
    request.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }

  return request;
};

const responseHandler = async (response: AxiosResponse<any>) => {
  return response.data;
};

const errorHandler = async (error: AxiosError) => {
  const isAuthError = error?.response?.status === 401;
  const isLoginRequest = error.request?.responseURL?.includes('login');

  if (isAuthError && !isLoginRequest) {
    window.localStorage.removeItem('token');
    window.location.href = pathNames.login;
  }

  return Promise.reject(error);
};

httpRequest.interceptors.request.use(requestHandler);
httpRequest.interceptors.response.use(responseHandler, errorHandler);

export default httpRequest;
