import AllOrders from 'pages/mobile/all-orders/all-orders';
import NewOrders from 'pages/mobile/new-orders/new-orders';
import Deliveries from 'pages/mobile/deliveries/deliveries';
import Account from 'pages/mobile/account/account';
import Inventory from 'pages/mobile/inventory/inventory';
import Login from 'pages/login/login';
import Error from 'pages/error/error';
import Profile from 'pages/mobile/profile/profile';
import Help from 'pages/mobile/help/help';
import InventoryDetail from 'pages/mobile/inventory-detail/inventory-detail';
import OrderDetail from 'pages/mobile/order-detail/order-detail';

import AllOrdersWeb from 'pages/web/all-orders/all-orders';
import AccountWeb from 'pages/web/account/account';
import DeliveriesWeb from 'pages/web/deliveries/deliveries';
import InventoryWeb from 'pages/web/inventory/inventory';
import HelpWeb from 'pages/web/help/help';
import OrderDetailWeb from 'pages/web/order-detail/order-detail';

import { OrderStatus, ScreenType } from 'lib/enums';
import { Navigate, RouteProps } from 'react-router-dom';

import useWindowResize from './useWindowResize';

export type IPaths =
  | '/'
  | '/login'
  | '/all-orders'
  | '/new-orders'
  | '/deliveries'
  | '/account'
  | '/help'
  | '/inventory'
  | '*'
  | '/404'
  | '/dashboard'
  | '/profile'
  | '/inventory/detail/:productId'
  | '/order/:orderId';

type IPathNames =
  | 'main'
  | 'login'
  | 'allOrders'
  | 'newOrders'
  | 'deliveries'
  | 'account'
  | 'help'
  | 'inventory'
  | 'error'
  | 'e404'
  | 'dashboard'
  | 'profile'
  | 'inventoryDetail'
  | 'order';

// eslint-disable-next-line no-unused-vars
export const pathNames: { [key in IPathNames]: IPaths } = {
  main: '/',
  login: '/login',
  allOrders: '/all-orders',
  newOrders: '/new-orders',
  deliveries: '/deliveries',
  account: '/account',
  help: '/help',
  inventory: '/inventory',
  error: '*',
  dashboard: '/dashboard',
  profile: '/profile',
  inventoryDetail: '/inventory/detail/:productId',
  e404: '/404',
  order: '/order/:orderId',
};

export const mobilePathNamesWithoutTab = [
  pathNames.main,
  pathNames.login,
  pathNames.inventory,
  pathNames.error,
  pathNames.help,
  pathNames.inventoryDetail.replace(':productId', ''),
];

const useRouter = () => {
  const currentWindowType = useWindowResize();
  const isTabletOrMobile =
    currentWindowType === ScreenType.TABLET ||
    currentWindowType === ScreenType.MOBILE;
  const currentPlatform = isTabletOrMobile ? 'mobile' : 'web';
  const userIsLoggedIn = window.localStorage.getItem('token');
  const defaultRoute = userIsLoggedIn ? (
    <Navigate
      to={`${pathNames.allOrders}?status=${OrderStatus.PROCESSING}`}
      replace
    />
  ) : (
    <Login />
  );

  const routesForPlatform: {
    mobile: Array<RouteProps>;
    web: Array<RouteProps>;
  } = {
    mobile: [
      { path: pathNames.error, element: <Error /> },
      { path: pathNames.error, element: <Error /> },
      { path: pathNames.main, element: defaultRoute },
      { path: pathNames.login, element: defaultRoute },
      { path: pathNames.allOrders, element: <AllOrders /> },
      { path: pathNames.newOrders, element: <NewOrders /> },
      { path: pathNames.deliveries, element: <Deliveries /> },
      { path: pathNames.account, element: <Account /> },
      { path: pathNames.inventory, element: <Inventory /> },
      { path: pathNames.profile, element: <Profile /> },
      { path: pathNames.help, element: <Help /> },
      { path: pathNames.inventoryDetail, element: <InventoryDetail /> },
      { path: pathNames.order, element: <OrderDetail /> },
    ],
    web: [
      {
        path: pathNames.error,
        element: <Navigate to={pathNames.e404} replace />,
      },
      { path: pathNames.e404, element: <Error /> },
      { path: pathNames.main, element: defaultRoute },
      { path: pathNames.login, element: defaultRoute },
      { path: pathNames.allOrders, element: <AllOrdersWeb /> },
      { path: pathNames.newOrders, element: <AllOrdersWeb /> },
      { path: pathNames.deliveries, element: <DeliveriesWeb /> },
      { path: pathNames.account, element: <AccountWeb /> },
      { path: pathNames.inventory, element: <InventoryWeb /> },
      {
        path: pathNames.inventoryDetail,
        element: <Navigate to={pathNames.inventory} replace />,
      },
      { path: pathNames.help, element: <HelpWeb /> },
      { path: pathNames.profile, element: <AccountWeb /> },
      { path: pathNames.order, element: <OrderDetailWeb /> },
    ],
  };

  const routes: Array<RouteProps> = routesForPlatform[currentPlatform];

  return { routes, currentPlatform };
};

export default useRouter;
