import { Typography } from '@mui/material';
import { FC } from 'react';

import styles from './no-result.module.scss';

interface INoResult {
  title: string;
}

const NoResult: FC<INoResult> = ({ title }) => {
  return (
    <div className={styles.noResultContainer}>
      <Typography>{title}</Typography>
    </div>
  );
};

export default NoResult;
