import { Dispatch, FC, SetStateAction } from 'react';

import styles from './tabs.module.scss';

interface ITabs {
  day: 'tomorrow' | 'today';
  setDay: Dispatch<SetStateAction<'today' | 'tomorrow'>>;
}

const Tabs: FC<ITabs> = ({ day, setDay }) => {
  return (
    <div className={styles.tabsContainer}>
      <div
        onClick={() => setDay('today')}
        className={`${styles.tabItemContainer} ${
          day === 'today' && styles.active
        }`}
      >
        Today
      </div>
      <div
        onClick={() => setDay('tomorrow')}
        className={`${styles.tabItemContainer} ${
          day === 'tomorrow' && styles.active
        }`}
      >
        Tomorrow
      </div>
    </div>
  );
};

export default Tabs;
