import { getCurrentUser } from 'http-requests';
import { atom } from 'jotai';
import { IUser } from 'models';
import { atomWithStorage } from 'jotai/utils';

export const userToken = atomWithStorage<string | null>('token', null);

export const currentUserNeedUpdate = atom<boolean>(false);

export const currentUser = atom<Promise<IUser.IUser>>(async get => {
  const token = get(userToken);
  get(currentUserNeedUpdate);

  try {
    if (token) {
      return await getCurrentUser();
    }
    return {} as IUser.IUser;
  } catch (error) {
    return {} as IUser.IUser;
  }
});
