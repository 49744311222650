import { Typography } from '@mui/material';
import { deliveryDateTimeFormatter } from 'lib/utils';
import { FC } from 'react';
import colors from 'styles/variables.scss';

import styles from './header.module.scss';

interface IHeaderMultipleProduct {
  orderId: string;
  itemLength: number;
  date: string;
}

const HeaderMultipleProduct: FC<IHeaderMultipleProduct> = ({
  orderId,
  itemLength,
  date,
}) => {
  return (
    <div className={styles.header}>
      <div>
        <Typography fontWeight="bold">{orderId}</Typography>
        <Typography mt={0} variant="caption" color={colors.black50}>
          {deliveryDateTimeFormatter(date)}
        </Typography>
      </div>
      <Typography variant="body2" color={colors.black50}>
        {itemLength} Products
      </Typography>
    </div>
  );
};

export default HeaderMultipleProduct;
