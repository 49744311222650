import {
  MobileContainer,
  MobileHeader,
  MobileOrderCard,
  MobileOrderFilter,
  TopTab,
  NoResult,
} from 'components';
import Pagination from '@mui/material/Pagination';
import { useFilters } from 'hooks';
import { useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { currentUser } from 'store';
import styles from './all-orders.module.scss';

const AllOrders = () => {
  const {
    orders,
    clearTempFilters,
    resetTempFilters,
    pageDetail,
    changePage,
    loading,
    totalPage,
  } = useFilters();

  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [user] = useAtom(currentUser);

  const operationAllowed = useMemo(() => {
    const operationAllowedAttribute = user?.custom_attributes?.find(
      ({ attribute_code }) => attribute_code === 'operation_allowed'
    );

    return operationAllowedAttribute?.value === '1';
  }, [user]);

  const headerRightSideAction = () => {
    if (!isFilterOpen) {
      resetTempFilters();
      setFilterOpen(true);
    } else {
      clearTempFilters();
    }
  };

  return (
    <>
      <MobileHeader
        rightIconAction={() => {
          headerRightSideAction();
        }}
        rightIconType={isFilterOpen ? 'clear' : 'filter'}
        title={isFilterOpen ? 'Filtering' : 'All Orders'}
        closeButton={isFilterOpen}
        closeButtonAction={() => setFilterOpen(false)}
      />

      <MobileContainer withoutBottomTab={isFilterOpen}>
        {!isFilterOpen && <TopTab />}

        {!isFilterOpen && orders?.length > 0 && (
          <div className={styles.ordersContainer}>
            {orders.map(order => (
              <MobileOrderCard
                key={order.merchant_order_id}
                operationAllowed={operationAllowed}
                {...order}
              />
            ))}
          </div>
        )}

        {!isFilterOpen && orders?.length === 0 && !loading && (
          <NoResult title="Orders not found" />
        )}

        {isFilterOpen && <MobileOrderFilter setFilterOpen={setFilterOpen} />}

        {pageDetail?.currentPage && !isFilterOpen && totalPage > 1 && (
          <div className={styles.pagination}>
            <Pagination
              disabled={loading}
              size="medium"
              onChange={(_, page) => {
                window.scrollTo(0, 0);
                changePage(page);
              }}
              count={totalPage}
              page={pageDetail.currentPage}
            />
          </div>
        )}
      </MobileContainer>
    </>
  );
};

export default AllOrders;
