import { atom } from 'jotai';
import { OrderStatus } from 'lib/enums';
import { IOrders } from 'models';

export const topTabQuickFilters = atom<Array<OrderStatus>>([
  OrderStatus.PROCESSING,
  OrderStatus.ACCEPTED,
  OrderStatus.ON_HOLD,
  OrderStatus.READY_TO_DISPATCH,
  OrderStatus.DISPATCHED,
  OrderStatus.OUT_FOR_DELIVERY,
  OrderStatus.DELIVERED,
  OrderStatus.CLOSED,
]);

export const selectedTempFilters = atom<IOrders.IFilter>({ filters: {} });
export const selectedFilters = atom<IOrders.IFilter>({ filters: {} });
