import { FC } from 'react';
import { Typography } from '@mui/material';
// import { IOrders } from 'models';
import colors from 'styles/variables.scss';
import {
  cdnUrl,
  deliveryDateTimeFormatter,
  getStatusColorForOrderStatus,
} from 'lib/utils';
import { OrderStatus } from 'lib/enums';
import styles from './detail.module.scss';

interface IDetail {
  name: string;
  note?: string;
  status: OrderStatus;
  // price: IOrders.IPrice;
  date?: string;
  orderId?: string;
  image: string;
}

const Detail: FC<IDetail> = ({
  name,
  note,
  status,
  // price,
  date,
  orderId,
  image,
}) => {
  return (
    <div className={styles.swiperItem}>
      <img
        className={styles.image}
        width={80}
        height={94}
        alt={name}
        src={cdnUrl(image, 80, 94)}
      />
      <div className={styles.itemDetail}>
        <Typography fontWeight={500}>{orderId || name}</Typography>
        {orderId && <Typography fontSize="0.8125rem">{name}</Typography>}
        <Typography
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
          fontSize="0.8125rem"
          color={colors.black50}
        >
          {date ? deliveryDateTimeFormatter(date) : note}
        </Typography>
        <div className={styles.status}>
          <Typography
            color={getStatusColorForOrderStatus(status)}
            lineHeight="normal"
            variant="body2"
            fontWeight={500}
          >
            {status}
          </Typography>
          {/* <Typography lineHeight="normal" variant="h6" fontWeight={500}>
            {`${price.currency} ${price.amount}`}
          </Typography> */}
        </div>
      </div>
    </div>
  );
};

export default Detail;
