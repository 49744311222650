import { Divider, Grid, TextField, Typography } from '@mui/material';
import { Button, MobileContainer, MobileHeader } from 'components';
import { FC, Suspense, useEffect, useState } from 'react';
import colors from 'styles/variables.scss';
import {
  updateStock,
  getProductDetail,
} from 'http-requests/inventory-requests';
import { useNavigate, useParams } from 'react-router-dom';
import { IInventory } from 'models';
import { useSetAtom } from 'jotai';
import { alerts, contentLoading } from 'store';
import { StockStatuses } from 'lib/enums';
import { cdnUrl } from 'lib/utils';
import ImageModal from 'components/modals/image-modal';
import PriceQtyRow from './price-qty-row/price-qty-row';
import styles from './inventory-detail.module.scss';

const InventoryDetail: FC = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<IInventory.IProductDetail>(
    {} as IInventory.IProductDetail
  );

  const updateAlert = useSetAtom(alerts);
  const setLoading = useSetAtom(contentLoading);

  const [newStock, setNewStock] = useState<number | string>(
    product.stock_qty || 0
  );
  const [imageUrl, setImageUrl] = useState<string>('');

  const merchantPrice = `${product?.currency} ${product?.cost || '-'}`;

  const changeStock = async () => {
    try {
      setLoading(true);
      await updateStock({
        productId: product.id,
        qty: newStock === '' ? 0 : +newStock,
      });

      navigate(-1);
      updateAlert([{ severity: 'success', title: 'Stock is updated!' }]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (+newStock < 0) {
      setNewStock(0);
    }
  }, [newStock]);

  useEffect(() => {
    setLoading(true);
    const getDetail = async () => {
      try {
        if (productId) {
          const response = await getProductDetail({
            productId: +productId,
          });

          setProduct(response);
          setNewStock(response.stock_qty);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    getDetail();
  }, [productId]);

  return (
    <Suspense>
      <ImageModal
        open={!!imageUrl}
        onClose={() => setImageUrl('')}
        imageUrl={imageUrl}
      />

      <MobileHeader backButton title={product?.sku?.toUpperCase()} />
      <MobileContainer withoutBottomTab whiteBackground>
        <div className={styles.inventoryDetailContainer}>
          <div className={styles.cardContainer}>
            <div
              onClick={() => setImageUrl(`catalog/product/${product?.image}`)}
            >
              <img
                width={84}
                height={94}
                alt={product.name}
                src={cdnUrl(
                  product?.image ? `catalog/product/${product?.image}` : '',
                  84,
                  94
                )}
              />
            </div>
            <div className={styles.informationContainer}>
              <Typography fontWeight="bold">{`SKU: ${product.sku?.toUpperCase()}`}</Typography>
              <Typography>{product.name}</Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                color={
                  product.stock_status === StockStatuses.IN_STOCK
                    ? colors.green
                    : colors.red
                }
                sx={{ mt: 1, mb: '10px' }}
              >
                {product.stock_status === StockStatuses.IN_STOCK
                  ? 'In Stock'
                  : 'Out of Stock'}
              </Typography>
              <PriceQtyRow title="Merchant Price" value={merchantPrice} />
              <PriceQtyRow
                title="Quantity"
                value={product?.stock_qty?.toString()}
              />
            </div>
          </div>
          <Typography sx={{ mt: 3 }} align="center" fontWeight={500}>
            Change the Quantity
          </Typography>
          <div className={styles.changeQtyContainer}>
            <Button
              onClick={() => setNewStock(prev => +prev - 1)}
              sx={{ fontSize: '24px', width: '70px', height: '44px' }}
              text="-"
              variant="outlined"
            />
            <TextField
              variant="standard"
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                style: {
                  height: 44,
                  width: 50,
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
                disableUnderline: true,
              }}
              onChange={({ target }) => {
                const regex = /^[0-9\b]+$/;

                if (target.value === '' || regex.test(target.value)) {
                  setNewStock(target.value);
                }
              }}
              value={newStock}
            />
            <Button
              onClick={() => setNewStock(prev => +prev + 1)}
              sx={{ fontSize: '24px', width: '70px', height: '44px' }}
              text="+"
              variant="outlined"
            />
          </div>

          <div className={styles.footerContainer}>
            <Divider />
            <Grid container sx={{ pl: 2, pr: 2 }}>
              <Button
                onClick={() => changeStock()}
                disabled={Number(newStock) === product.stock_qty}
                text="Save"
                variant="contained"
                fullWidth
                sx={{ mt: 2, pt: 1.3, pb: 1.3 }}
              />
            </Grid>
          </div>
        </div>
      </MobileContainer>
    </Suspense>
  );
};

export default InventoryDetail;
