import { createTheme } from '@mui/material/styles';
import colors from 'styles/variables.scss';

export const buttonTheme = createTheme({
  palette: {
    primary: {
      main: colors.black,
    },
    secondary: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
    success: {
      main: colors.green,
    },
    info: {
      main: colors.brNavyBlue,
    },
  },
});
