/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC,
  PropsWithChildren,
  Suspense,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  ListItemIcon,
  Typography,
  Menu,
  MenuItem as Item,
} from '@mui/material';

import { sideBarMenu } from 'lib/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/new-logo.png';
import { pathNames } from 'hooks/useRouter';
import { removeRegisterDevice } from 'http-requests';
import { useAtomValue } from 'jotai';
import { currentUser } from 'store';
import Logout from '@mui/icons-material/Logout';
import colors from 'styles/variables.scss';
import styles from './web-wrapper.module.scss';
import NoUser from '../Logo/NoUser';

const MenuItem = ({
  title,
  icon,
  onClick,
  href,
  active,
  searchParam,
}: {
  title: string;
  icon: React.ReactNode;
  href: string;
  onClick: any;
  active: boolean;
  searchParam: string;
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      onClick={() => onClick(href, searchParam)}
      className={active ? styles.active : ''}
    >
      {icon}
      <Typography fontWeight={400}>{title}</Typography>
    </li>
  );
};

const WebWrapper: FC<PropsWithChildren> = ({ children }) => {
  const user = useAtomValue(currentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState<string>('Dashboard');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirect = (href: string, searchParam: string) => {
    if (!location.pathname.includes(href)) {
      navigate(href + searchParam);
    }
  };

  useEffect(() => {
    const title =
      sideBarMenu.find(menu => menu.href === location.pathname)?.title || '';

    setPageTitle(title);
  }, [location]);

  const logout = async () => {
    window.localStorage.removeItem('token');
    await removeRegisterDevice();
    navigate(pathNames.main);
  };

  const locationIsOrderDetail = location.pathname.includes('/order/');

  return location.pathname !== pathNames.login &&
    location.pathname !== pathNames.main &&
    location.pathname !== pathNames.e404 ? (
    <div
      style={{
        backgroundColor: locationIsOrderDetail
          ? colors.mobileBackgroundColor
          : colors.white,
      }}
      className={styles.webWrapper}
    >
      <Grid container direction="row">
        <nav className={styles.nav}>
          <img className={styles.logo} alt="joi" src={logo} />
          <ul>
            {sideBarMenu.map(s => (
              <MenuItem
                key={s.href}
                active={location.pathname === s.href}
                onClick={redirect}
                {...s}
              />
            ))}
          </ul>
          <div className={styles.sideBarFooterContainer}>
            <div onClick={handleClick} className={styles.sideBarFooter}>
              <NoUser />
              <Typography>{`Hello, ${user?.name || ''}!`}</Typography>
            </div>
          </div>
        </nav>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Item onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </Item>
        </Menu>
        <div className={styles.page}>
          <div className={styles.header}>
            <Typography fontWeight="bold" variant="h6">
              {pageTitle}
            </Typography>
          </div>
          <Suspense>{children}</Suspense>
        </div>
      </Grid>
    </div>
  ) : (
    <Suspense>{children}</Suspense>
  );
};

export default WebWrapper;
