import { Dialog, DialogContent } from '@mui/material';
import styles from './index.module.scss';

interface IDescriptionModal {
  open: boolean;
  onClose: () => void;
  description: string;
}

const DescriptionModal = ({
  open,
  onClose,
  description,
}: IDescriptionModal) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className={styles.description}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DescriptionModal;
