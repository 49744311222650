import SortIcon from '@mui/icons-material/Sort';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import { pathNames } from 'hooks/useRouter';
import { OrderStatus } from './enums';

export const sideBarMenu = [
  {
    title: 'Orders',
    icon: <SortIcon />,
    href: pathNames.allOrders,
    searchParam: `?status=${OrderStatus.PROCESSING}`,
  },
  {
    title: 'Deliveries',
    icon: <PlaylistAddCheckOutlinedIcon />,
    href: pathNames.deliveries,
    searchParam: '',
  },
  {
    title: 'Inventory',
    icon: <Inventory2OutlinedIcon />,
    href: pathNames.inventory,
    searchParam: '',
  },
  {
    title: 'Account',
    icon: <ManageAccountsOutlinedIcon />,
    href: pathNames.account,
    searchParam: '',
  },
  {
    title: 'Help',
    icon: <HeadsetMicOutlinedIcon />,
    href: pathNames.help,
    searchParam: '',
  },
];

export const rejectReasons = [
  { value: 'product_oos', label: 'Product is out of stock' },
  {
    value: 'product_can_not_prepared_on_time',
    label: 'Product cannot be prepared on time',
  },
  { value: 'other', label: 'Other' },
];
