import { useState } from 'react';
import { Button, CustomPagination } from 'components';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid';
import { IInventory } from 'models';
import { updateStock } from 'http-requests';
import { useInventoryFilters } from 'hooks';
import { cdnUrl } from 'lib/utils';
import { useSetAtom } from 'jotai';
import { alerts } from 'store';
import { StockStatuses } from 'lib/enums';
import Filter from './components/filter';
import styles from './inventory.module.scss';

const Inventory = () => {
  const {
    products,
    addToTempFilters,
    clearTempFilters,
    applyTempFilters,
    tempFilters,
    loading,
    setLoading,
    pageDetail,
    changePage,
    sortData,
    retrieve: retrieveProducts,
  } = useInventoryFilters();

  const [openFilter, setOpenFilter] = useState(false);

  const alertArray = useSetAtom(alerts);

  const columns: Array<GridColDef> = [
    { field: 'sku', headerName: 'SKU', flex: 0.3 },
    {
      field: 'image',
      headerName: 'Thumbnail',
      flex: 0.2,
      renderCell: params => (
        <img
          src={cdnUrl(`catalog/product/${params.row.image}`, 75, 75)}
          alt={params.row.name}
          loading="lazy"
          width={75}
        />
      ),
    },
    { field: 'name', headerName: 'Name', flex: 0.5 },
    {
      field: 'cost',
      headerName: "Joi's Merchant Price",
      renderCell(params: any) {
        return params.row.cost;
      },
      flex: 0.3,
    },
    {
      field: 'stock_status',
      headerName: 'Stock Status',
      renderCell(params: any) {
        return +params.row.stock_status === StockStatuses.IN_STOCK
          ? 'In Stock'
          : 'Out of Stock';
      },
      flex: 0.2,
    },
    {
      field: 'approval',
      headerName: 'Approval Status',
      flex: 0.2,
    },
    {
      field: 'stock_qty',
      headerName: 'Quantity',
      flex: 0.14,
      editable: true,
      type: 'number',
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
  ];

  const update = async (productId: number, qty: number) => {
    try {
      setLoading(true);

      await updateStock({ productId, qty });
      await retrieveProducts();

      alertArray(prev => [
        ...prev,
        {
          severity: 'success',
          title: `Quantity was changed to ${qty}!`,
        },
      ]);
    } catch (error: any) {
      alertArray(prev => [
        ...prev,
        { severity: 'error', title: error.response?.data.message },
      ]);
    }
  };

  return (
    <div className={styles.inventoryContainer}>
      <Button
        onClick={() => setOpenFilter(prev => !prev)}
        sx={{ alignSelf: 'flex-end', width: 115 }}
        variant="contained"
        color="info"
        text="Filter"
      />
      {openFilter && (
        <Filter
          {...tempFilters}
          addToFilter={addToTempFilters}
          setOpenFilter={setOpenFilter}
          clearFilter={clearTempFilters}
          applyFilter={applyTempFilters}
        />
      )}
      <DataGrid
        autoHeight
        hideFooter
        getRowId={(row: IInventory.IProduct) => row.id}
        rows={products || []}
        rowHeight={75}
        columns={columns}
        onSortModelChange={model => {
          sortData(model[0]?.field, model[0]?.sort || 'asc');
        }}
        getCellClassName={(params: GridCellParams<any>) => {
          if (params.field === 'stock_qty') {
            return styles.editable;
          }
          return '';
        }}
        pagination
        loading={loading}
        onCellEditStop={async row => {
          await update(+row.id, row.value);
        }}
        localeText={{
          noRowsLabel: 'No products',
        }}
      />
      {products?.length > 0 && (
        <CustomPagination
          change={changePage}
          currentPage={pageDetail.currentPage}
          totalCount={pageDetail.totalCount}
        />
      )}
    </div>
  );
};

export default Inventory;
