import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { Row } from 'components';
import { deliveryDateTimeFormatter, deliveryTimeFormatter } from 'lib/utils';
import { IOrders } from 'models';
import colors from 'styles/variables.scss';
import styles from './delivery-card.module.scss';

interface IDeliveryCard extends Omit<IOrders.IOrderDetail, 'items'> {
  operationAllowed: boolean;
}

const subTextProps: TypographyProps = {
  variant: 'body2',
  color: colors.black50,
};

const DeliveryCard: FC<IDeliveryCard> = ({ ...props }) => {
  const { shipping_address = {} as any, delivery_city } =
    props?.extension_attributes || {};

  const {
    street,
    city,
    country,
    postcode,
    region,
    building_no,
    floor_no,
    door_no,
  } = shipping_address || {};

  const addressParts = [
    street,
    region,
    building_no && `Building No: ${building_no}`,
    floor_no && `Floor No: ${floor_no}`,
    door_no && `Door No: ${door_no}`,
    city,
    country,
    postcode,
  ].filter(Boolean);

  const address = addressParts.join(' ');

  return (
    <div className={styles.deliveryCardContainer}>
      <Typography sx={{ mb: 2.5 }} {...subTextProps}>
        Delivery Information
      </Typography>
      <Row title="Delivery City" value={delivery_city || '-'} />
      <Row
        title="Delivery Date"
        value={deliveryDateTimeFormatter(
          props?.extension_attributes?.delivery_date
        )}
      />
      <Row
        title={props.operationAllowed ? 'Delivery Time' : 'Pick Up Time'}
        value={deliveryTimeFormatter(
          props.operationAllowed
            ? props?.extension_attributes?.delivery_time
            : props?.extension_attributes?.pickup_time
        )}
      />
      <Row title="Recipient Name" value={shipping_address?.fullname} />
      <Row phone title="Phone" value={shipping_address?.phone} />
      <Row borderless title="Address" value={address} />
    </div>
  );
};

export default DeliveryCard;
