import { Button } from 'components';
import { Dispatch, FC, SetStateAction } from 'react';

import styles from './actions.module.scss';

interface IActions {
  disable?: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  acceptOrder: () => Promise<void>;
  isConfirmFlow: boolean;
  setPhotoUploadOpen: Dispatch<SetStateAction<boolean>>;
}

const Actions: FC<IActions> = ({
  disable,
  setOpenModal,
  acceptOrder,
  isConfirmFlow,
  setPhotoUploadOpen,
}) => {
  return (
    <div className={styles.actionsContainer}>
      <Button
        onClick={() => {
          setOpenModal(true);
        }}
        disabled={disable}
        fullWidth
        sx={{ height: 44 }}
        color="error"
        text={isConfirmFlow ? 'reject' : 'report an issue'}
        variant="outlined"
      />
      <Button
        onClick={() => {
          if (isConfirmFlow) {
            acceptOrder();
          } else {
            setPhotoUploadOpen(true);
          }
        }}
        disabled={disable}
        fullWidth
        sx={{ height: 44 }}
        color="success"
        text={isConfirmFlow ? 'accept' : 'Ready to dispatch'}
        variant="contained"
      />
    </div>
  );
};

export default Actions;
