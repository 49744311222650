import { Typography } from '@mui/material';
import { cdnUrl } from 'lib/utils';
import { IInventory } from 'models';
import { FC } from 'react';
import colors from 'styles/variables.scss';
import { StockStatuses } from 'lib/enums';
import styles from './list-item.module.scss';

interface IListItem extends IInventory.IProduct {
  // eslint-disable-next-line no-unused-vars
  getDetail: (id: number) => void;
}

const ListItem: FC<IListItem> = ({
  image,
  name,
  sku,
  getDetail,
  id,
  stock_status,
  stock_qty,
}) => {
  return (
    <div onClick={() => getDetail(id)} className={styles.listItemContainer}>
      <img
        alt={name}
        src={cdnUrl(`catalog/product/${image}`, 80, 94)}
        width={80}
        height={94}
      />
      <div>
        <Typography textTransform="uppercase" fontWeight="bold">
          {sku}
        </Typography>
        <Typography>{name}</Typography>
        <Typography color={colors.black50} variant="body2">
          Qty: {stock_qty}
        </Typography>
        <Typography
          sx={{ mt: 1.5 }}
          fontWeight={500}
          variant="body2"
          color={
            +stock_status === StockStatuses.IN_STOCK ? colors.green : colors.red
          }
        >
          {+stock_status === StockStatuses.IN_STOCK
            ? 'In Stock'
            : 'Out of Stock'}
        </Typography>
      </div>
    </div>
  );
};

export default ListItem;
