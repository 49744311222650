import useFilters from '../../hooks/useFilters';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTime } from 'luxon';

const DeliveryDateFilter = () => {
  const { tempFilters, convertAndAddDateToTempFilters } = useFilters(true);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DesktopDatePicker
        label="Minimum"
        format="yyyy-MM-dd"
        value={
          tempFilters.filters?.delivery_date__from
            ? DateTime.fromFormat(
                tempFilters.filters?.delivery_date__from,
                'yyyy-MM-dd'
              )
            : null
        }
        onChange={value =>
          convertAndAddDateToTempFilters(
            'delivery_date__from',
            value ? value.toFormat('yyyy-MM-dd') : null
          )
        }
      />

      <DesktopDatePicker
        label="Maximum"
        format="yyyy-MM-dd"
        value={
          tempFilters.filters?.delivery_date__to
            ? DateTime.fromFormat(
                tempFilters.filters?.delivery_date__to,
                'yyyy-MM-dd'
              )
            : null
        }
        onChange={value =>
          convertAndAddDateToTempFilters(
            'delivery_date__to',
            value ? value.toFormat('yyyy-MM-dd') : null
          )
        }
      />
    </LocalizationProvider>
  );
};

export default DeliveryDateFilter;
