import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';
import Paper from '@mui/material/Paper';
import SortIcon from '@mui/icons-material/Sort';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { pathNames } from 'hooks/useRouter';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './bottom-tab.module.scss';

const BottomTab: FC<PropsWithChildren> = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(location.pathname);

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 99 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={page}
        onChange={(event, newValue) => {
          setPage(newValue);
          navigation(newValue);
        }}
      >
        <BottomNavigationAction
          className={
            page === pathNames.allOrders ? styles.active : styles.inactive
          }
          value={pathNames.allOrders}
          label="All Orders"
          icon={<SortIcon />}
        />
        <BottomNavigationAction
          className={
            page === pathNames.newOrders ? styles.active : styles.inactive
          }
          value={pathNames.newOrders}
          label="New Orders"
          icon={<LocalFireDepartmentOutlinedIcon />}
        />
        <BottomNavigationAction
          className={
            page === pathNames.deliveries ? styles.active : styles.inactive
          }
          value={pathNames.deliveries}
          label="Deliveries"
          icon={<PlaylistAddCheckOutlinedIcon />}
        />
        <BottomNavigationAction
          className={
            page === pathNames.account ? styles.active : styles.inactive
          }
          value={pathNames.account}
          label="Account"
          icon={<ManageAccountsOutlinedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomTab;
