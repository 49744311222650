import { Alert, InnerLoadingOverlay, WebWrapper } from 'components';
import useRouter, { pathNames } from 'hooks/useRouter';
import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useWindowResize } from 'hooks';
import { ScreenType } from 'lib/enums';

import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

import styles from './app.module.scss';

const App = () => {
  const screen = useWindowResize();
  const location = useLocation();
  const navigate = useNavigate();
  const { routes } = useRouter();

  useEffect(() => {
    if (
      !window.localStorage.getItem('token') &&
      location.pathname !== pathNames.login
    ) {
      navigate(pathNames.login, { replace: true });
    }
  }, [location]);

  const isMobile = screen === ScreenType.TABLET;

  return isMobile ? (
    <div className={styles.container}>
      <InnerLoadingOverlay />
      <Alert />
      <Suspense>
        <Routes>
          {routes.map(e => (
            <Route key={e.path} {...e} />
          ))}
        </Routes>
      </Suspense>
    </div>
  ) : (
    <Suspense>
      <WebWrapper>
        <Alert />
        <Routes>
          {routes.map(e => (
            <Route key={e.path} {...e} />
          ))}
        </Routes>
      </WebWrapper>
    </Suspense>
  );
};

export default App;
