import { FC, useState } from 'react';
import { useSetAtom } from 'jotai';
import { saveAs } from 'file-saver';
import { Typography } from '@mui/material';
import { Button, RejectPopup, DescriptionModal } from 'components';
import {
  acceptOrderRequest,
  dispatchOrderRequest,
  rejectOrderRequest,
  updateStock,
} from 'http-requests';
import { OrderStatus } from 'lib/enums';
import { cdnUrl } from 'lib/utils';
import { IOrders } from 'models';
import colors from 'styles/variables.scss';
import { contentLoading } from 'store';
import styles from './order-item.module.scss';
import PhotoUpload from '../photo-upload/photo-upload';

interface IOrderItem extends IOrders.IOrderDetailItem {
  orderStatus: OrderStatus;
  grandTotal: string;
  getOrder: () => void;
  disable?: boolean;
  currency: string;
  canShip: boolean;
  // eslint-disable-next-line no-unused-vars
  onClickImage: (imageUrl: string) => void;
}

const OrderItem: FC<IOrderItem> = ({ ...props }) => {
  const {
    name,
    image,
    cost = 0,
    sku,
    qty_ordered,
    currency,
    orderStatus,
    getOrder,
    custom_options = [],
    item_id,
    product_id,
    status,
    canShip,
    description,
    onClickImage,
  } = props;

  const setLoading = useSetAtom(contentLoading);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDescriptionModal, setOpenDescriptionModal] =
    useState<boolean>(false);
  const [photoUploadOpen, setPhotoUploadOpen] = useState<boolean>(false);

  const acceptOrder = async () => {
    try {
      setLoading(true);
      const response = await acceptOrderRequest(item_id.toString());

      if (response) {
        getOrder();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const reject = async (reason: string, detail: string) => {
    try {
      setLoading(true);
      setOpenModal(false);

      const response = await rejectOrderRequest(item_id.toString(), {
        reject_reason: reason,
        reject_comment: detail,
      });

      if (reason === 'product_oos') {
        await updateStock({ productId: product_id, qty: 0 });
      }

      if (response) {
        getOrder();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const dispatchOrder = async (base64: string) => {
    setPhotoUploadOpen(false);

    try {
      if (base64) {
        setLoading(true);
        const response = await dispatchOrderRequest({
          itemId: item_id.toString(),
          base64,
        });

        if (response) {
          getOrder();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const downloadImage = (url: string) => {
    saveAs(url, item_id.toString());
  };

  const showButtons =
    // @ts-ignore
    orderStatus === 'Processing' || orderStatus === 'Accepted';

  return (
    <>
      <DescriptionModal
        open={openDescriptionModal}
        onClose={() => setOpenDescriptionModal(false)}
        description={description}
      />

      <div className={styles.orderItemContainer}>
        <div
          onClick={() => onClickImage(`catalog/product${image}`)}
          className={styles.imageContainer}
        >
          <img
            width={92}
            height={108}
            alt={name}
            src={cdnUrl(`catalog/product/${image}`, 92, 108)}
          />
        </div>

        <div className={styles.itemDetailContainer}>
          <div className={styles.itemDetailInnerContainer}>
            <div style={{ flex: 1.3, minWidth: 100 }}>
              <Typography fontSize="1.125rem" variant="h6">
                {name}
              </Typography>
              <Typography variant="body2">SKU: {sku}</Typography>
              <Button
                variant="text"
                text="Product Detail"
                size="small"
                className={styles.detailButton}
                onClick={() => setOpenDescriptionModal(true)}
              />
            </div>
            <div style={{ flex: 0.5 }}>
              <Typography variant="body2">Quantity</Typography>
              <Typography variant="body2" color={colors.black50}>
                {qty_ordered}
              </Typography>
            </div>
            <div style={{ flex: 0.5 }}>
              <Typography variant="body2">Item Status</Typography>
              <Typography variant="body2">{status}</Typography>
            </div>
            <div style={{ flex: 0.5 }}>
              <Typography variant="body2">Cost Price</Typography>
              <Typography variant="body2" color={colors.black50}>
                {`${currency} ${+cost * +qty_ordered}`}
              </Typography>
            </div>
          </div>
          {custom_options.length !== 0 && (
            <div className={styles.customOptionsContainer}>
              <Typography fontWeight={500}>Custom Options</Typography>
              {custom_options.map(option => {
                return (
                  <div
                    key={option.title}
                    style={{
                      display: 'flex',
                      wordWrap: 'break-word',
                    }}
                  >
                    <Typography variant="body2" fontWeight={500}>
                      {`${option.title}:`}
                    </Typography>
                    {option.value.startsWith('https://') ? (
                      <Typography
                        onClick={() => downloadImage(option.value)}
                        sx={{
                          ml: 1,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        variant="body2"
                        color={colors.navyBlue}
                      >
                        Click to download
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ ml: 1 }}
                        variant="body2"
                        color={colors.black50}
                        dangerouslySetInnerHTML={{ __html: option.value }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {/* @ts-ignore */}
        {orderStatus === 'Processing' &&
          orderStatus !== 'On Hold' &&
          status === 'New' && (
            <div className={styles.actionContainer}>
              <Button
                onClick={() => acceptOrder()}
                variant="contained"
                color="success"
                text="Accept"
              />
              <Button
                onClick={() => setOpenModal(true)}
                variant="outlined"
                color="error"
                text="Reject"
              />
            </div>
          )}
        {/* @ts-ignore */}
        {orderStatus !== 'On Hold' &&
          status === 'Accepted' &&
          !canShip &&
          showButtons && (
            <div className={styles.actionContainer}>
              <Button
                onClick={() => setPhotoUploadOpen(true)}
                variant="contained"
                color="success"
                text="Ready to Dispatch"
              />
              <Button
                onClick={() => setOpenModal(true)}
                variant="outlined"
                color="error"
                text="Report an issue"
              />
            </div>
          )}

        <RejectPopup reject={reject} open={openModal} setOpen={setOpenModal} />

        <PhotoUpload
          dispatchOrder={dispatchOrder}
          open={photoUploadOpen}
          setOpen={setPhotoUploadOpen}
        />
      </div>
    </>
  );
};

export default OrderItem;
