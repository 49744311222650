const NoUser = ({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    id="\u03A9Elements_Avatar_A.Image_a.Enabled"
    data-name="\u03A9Elements/Avatar/A.Image/a.Enabled"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 40"
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Ellipse_1754"
          data-name="Ellipse 1754"
          cx={20}
          cy={20}
          r={20}
          style={{
            fill: '#7c7c7c',
            stroke: '#707070',
          }}
        />
      </clipPath>
      <style>{'.cls-4{fill:#b8b8b8}'}</style>
    </defs>
    <circle
      id="Ellipse_1751"
      data-name="Ellipse 1751"
      cx={20}
      cy={20}
      r={20}
      style={{
        fill: '#e6e6e6',
      }}
    />
    <g
      id="Mask_Group_27"
      data-name="Mask Group 27"
      style={{
        clipPath: 'url(#clip-path)',
      }}
    >
      <g
        id="Group_11481"
        data-name="Group 11481"
        transform="translate(-3774.601 -178.214)"
      >
        <path
          id="Path_10155"
          data-name="Path 10155"
          className="cls-4"
          d="M234.249 58.018a8.009 8.009 0 1 0-8.009-8.009 8.012 8.012 0 0 0 8.009 8.009Z"
          transform="translate(3560.317 144.462)"
        />
        <path
          id="Path_10156"
          data-name="Path 10156"
          className="cls-4"
          d="M170.341 304.693a13.856 13.856 0 0 0-.943-1.757 11.91 11.91 0 0 0-8.143-5.143 1.693 1.693 0 0 0-1.2.3 10.034 10.034 0 0 1-11.829 0 1.7 1.7 0 0 0-1.2-.3 11.765 11.765 0 0 0-8.143 5.143 9.955 9.955 0 0 0-.943 1.757.816.816 0 0 0 .043.771c.343.557.729 1.157 1.114 1.671a15 15 0 0 0 1.886 2.143 17.619 17.619 0 0 0 1.928 1.629 18.772 18.772 0 0 0 22.457 0 24.081 24.081 0 0 0 1.928-1.629 15.01 15.01 0 0 0 1.886-2.143 16.192 16.192 0 0 0 1.114-1.671.967.967 0 0 0 .043-.771Z"
          transform="translate(3640.469 -94.208)"
        />
      </g>
    </g>
  </svg>
);

export default NoUser;
