import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import colors from 'styles/variables.scss';
import { useAtomValue } from 'jotai';
import { topTabQuickFilters } from 'store';
import { Button } from 'components';
import useFilters from 'hooks/useFilters';
import { getOrderStatusLabel } from 'lib/utils';
import { Cities } from 'lib/enums';

import styles from './mobile-order-filter.module.scss';
import PurchaseDateFilter from '../DateFilters/PurchaseDateFilter';
import DeliveryDateFilter from '../DateFilters/DeliveryDateFilter';

interface IFilter {
  setFilterOpen: Dispatch<SetStateAction<boolean>>;
  withoutQuickFilters?: boolean;
}

const Filter: FC<IFilter> = ({ setFilterOpen, withoutQuickFilters }) => {
  const {
    tempFilters,
    addToTempFilters,
    applyTempFilters,
    addQuickFiltersToTemp,
    checkQuickFilterIsInTheTemp,
    filters,
  } = useFilters(true);
  const quickFilters = useAtomValue(topTabQuickFilters);

  const cityData = Object.values(Cities);

  return (
    <div className={styles.filterContainer}>
      {!withoutQuickFilters && (
        <>
          <div className={styles.status}>
            <Typography color={colors.black50} variant="body2">
              Status
            </Typography>

            <div className={styles.statusOptions}>
              {quickFilters.map(item => {
                return (
                  <FormControl
                    key={item}
                    onClick={() => addQuickFiltersToTemp(item)}
                  >
                    <FormControlLabel
                      style={{ pointerEvents: 'none' }}
                      label={getOrderStatusLabel(item)}
                      control={
                        <Checkbox
                          style={{ pointerEvents: 'auto' }}
                          checked={checkQuickFilterIsInTheTemp(item) || false}
                        />
                      }
                    />
                  </FormControl>
                );
              })}
            </div>
          </div>

          <Divider />
        </>
      )}

      <TextField
        label="Order ID"
        defaultValue=""
        value={tempFilters.filters?.increment_id || ''}
        onChange={e => addToTempFilters('increment_id', e.target.value)}
      />

      <TextField
        sx={{ mt: 2 }}
        label="SKU ID"
        value={tempFilters.filters?.sku || ''}
        onChange={e => addToTempFilters('sku', e.target.value)}
      />

      <Divider />

      {/* <Typography color={colors.black50} variant="body2">
          Grand Total
        </Typography>
        <div className={styles.rowArea}>
          <TextField
            type="number"
            sx={{ flex: 1 }}
            label="Minimum"
            value={tempFilters.filters?.['grand_total.from'] || ''}
            onChange={e => addToTempFilters('grand_total.from', e.target.value)}
          />
          <TextField
            type="number"
            sx={{ flex: 1 }}
            label="Maximum"
            value={tempFilters.filters?.['grand_total.to'] || ''}
            onChange={e => addToTempFilters('grand_total.to', e.target.value)}
          />
        </div>
        <div className={styles.divider} /> */}

      <Typography color={colors.black50} variant="body2">
        Delivery City
      </Typography>

      <FormControl sx={{ mt: 2 }}>
        <InputLabel id="city-select-label">Delivery City</InputLabel>
        <Select
          multiple
          labelId="city-select-label"
          id="city-select"
          variant="outlined"
          value={tempFilters.filters?.delivery_city?.split(',') || []}
          onChange={({ target: { value } }) => {
            let cities = typeof value === 'string' ? value.split(',') : value;
            cities = cities.filter(city => city.trim() !== '');

            addToTempFilters('delivery_city', cities.toString());
          }}
          renderValue={selected => selected.join(', ')}
          label="Delivery City"
          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
        >
          {cityData
            .sort(a => (filters?.filters?.delivery_city?.includes(a) ? -1 : 1))
            .map(city => (
              <MenuItem key={city} value={city}>
                <Checkbox
                  checked={
                    tempFilters?.filters?.delivery_city?.includes(city) || false
                  }
                />
                <ListItemText primary={city} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Divider />

      <Typography color={colors.black50} variant="body2">
        Delivery Date
      </Typography>

      <div className={styles.rowArea}>
        <DeliveryDateFilter />
      </div>

      <Divider />

      <Typography color={colors.black50} variant="body2">
        Purchase Date
      </Typography>

      <div className={styles.rowArea}>
        <PurchaseDateFilter />
      </div>

      <div className={styles.applyContainer}>
        <Button
          onClick={() => {
            applyTempFilters();
            setFilterOpen(false);
            window.scrollTo(0, 0);
          }}
          fullWidth
          text="apply"
          variant="contained"
        />
      </div>
    </div>
  );
};

export default Filter;
