/* eslint-disable no-unused-vars */
export enum ScreenSizes {
  DESKTOP = 1280,
  TABLET = 1024,
  MOBILE = 768,
}

export enum ScreenType {
  DESKTOP,
  TABLET,
  MOBILE,
}

export enum OrderStatus {
  ACCEPTED = 'accepted',
  CLOSED = 'closed',
  DELIVERED = 'complete',
  ON_HOLD = 'holded',
  PROCESSING = 'processing',
  READY_TO_DISPATCH = 'ready_to_ship',
  DISPATCHED = 'dispatched',
  OUT_FOR_DELIVERY = 'out_for_delivery',
}

export enum Cities {
  ABU_DHABI = 'Abu Dhabi',
  AJMAN = 'Ajman',
  AL_AIN = 'Al Ain',
  AL_KHOBAR = 'Al Khobar',
  AMMAN = 'Amman',
  BEIRUT = 'Beirut',
  BURAYDAH = 'Buraydah',
  CAIRO_EAST = 'Cairo East',
  DAMMAM = 'Dammam',
  DHAHRAN = 'Dhahran',
  DOHA = 'Doha',
  DUBAI = 'Dubai',
  HAIL = 'Hail',
  JEDDAH = 'Jeddah',
  JORDAN = 'Jordan',
  KHAMIS_MUSHAIT = 'Khamis Mushait',
  MECCA = 'Mecca',
  MEDINE = 'Medina',
  MANAMA = 'Manama',
  MUSCAT = 'Muscat',
  RIYADH = 'Riyadh',
  SHARJAH = 'Sharjah',
}

export enum ApprovalStatuses {
  NOT_SUBMITTED,
  PENDING_NEW,
  APPROVED,
  UNAPPROVED,
  PENDING_UPDATE,
}

export enum StockStatuses {
  OUT_OF_STOCK,
  IN_STOCK,
}

export enum Statuses {
  ENABLED = 1,
  DISABLED,
}
