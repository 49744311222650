import { Divider, Link, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { currentUser } from 'store';
import colors from 'styles/variables.scss';

const Help = () => {
  const user = useAtomValue(currentUser);
  const helpEmail = user?.extension_attributes?.help_email;
  const helpTelephone = user?.extension_attributes?.help_telephone;
  const phoneText = helpTelephone?.split(/[\s,]+/) || [];

  return (
    <div>
      <Typography fontWeight={500} fontSize="1.375rem">
        We&apos;re always here to help!
      </Typography>
      <Typography variant="body2" color={colors.black50} sx={{ mt: 5.9 }}>
        Phone Number (from 9 am to 1 am)
      </Typography>
      <Typography sx={{ mt: 1.5 }}>
        {phoneText.map((text, index) => {
          const isPhone = text.includes('+');

          return (
            <Link
              key={index.toString()}
              href={isPhone ? `tel:${text}` : undefined}
              underline={isPhone ? 'always' : 'none'}
              color="inherit"
            >
              <Typography
                fontWeight="normal"
                variant={isPhone ? 'h6' : 'body1'}
                display="inline"
              >
                {isPhone ? text : <span>&nbsp;&nbsp;{text}&nbsp;&nbsp;</span>}
              </Typography>
            </Link>
          );
        })}
      </Typography>
      <Divider sx={{ width: 548, mt: 3, mb: 3 }} />
      <Typography variant="body2" color={colors.black50}>
        Email
      </Typography>
      <Typography sx={{ mt: 1.5 }} variant="h6" fontWeight="normal">
        {helpEmail}
      </Typography>
      <Divider sx={{ width: 548, mt: 3 }} />
    </div>
  );
};

export default Help;
