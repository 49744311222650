import { Typography } from '@mui/material';
import { FC } from 'react';
import colors from 'styles/variables.scss';

import styles from './price-qty-row.module.scss';

interface IPriceQtyRow {
  title: string;
  value: string;
}

const PriceQtyRow: FC<IPriceQtyRow> = ({ title, value }) => {
  return (
    <div className={styles.priceQtyRowContainer}>
      <div className={styles.item}>
        <Typography variant="body2" fontWeight={500}>
          {title}
        </Typography>
      </div>
      <div className={styles.item}>
        <Typography variant="body2" color={colors.black50}>
          {value}
        </Typography>
      </div>
    </div>
  );
};

export default PriceQtyRow;
