import { cdnUrl, deliveryDateTimeFormatter } from 'lib/utils';
import { IOrders } from 'models';

const printOrderDetail = ({
  order_id,
  purchase_date,
  operationAllowed,
  extension_attributes,
}: IOrders.IOrderDetail & { operationAllowed?: boolean }) => {
  const { delivery_date, delivery_time, pickup_time } = extension_attributes;
  return `<div>
          <span>${order_id}</span><br/><br/>
          <p><span>Order Date: </span>${deliveryDateTimeFormatter(
            purchase_date
          )}</p>
          <p><span>Delivery Date: </span>${delivery_date}</p>
          <p><span>${
            operationAllowed ? 'Delivery Time: ' : 'Pick Up Time: '
          }</span>${operationAllowed ? delivery_time : pickup_time}</p>
        </div>`;
};

const printShippingAddress = (
  shipping_address: IOrders.IOrderDetail['extension_attributes']['shipping_address']
) => {
  let pShippingAddress = '';

  if (shipping_address) {
    pShippingAddress = `
      <div style="width: 20rem;"><span>Recipient Info</span>
      <p>${shipping_address.fullname}</p>
    <p>${shipping_address.street}, ${shipping_address.region}, ${shipping_address.city}, ${shipping_address.country}</p>
    <p>T: ${shipping_address.phone}</p></div>`;
    return pShippingAddress;
  }

  return pShippingAddress;
};

const printProductItems = (
  items: IOrders.IOrder['items'],
  merchantName: string
) => {
  let pProductItems = '';
  const customOptions = items
    .map(item => {
      return (item?.custom_options || []).map(
        option =>
          `<p style="font-size: 12px; color: #00000080">${option.title}: ${option.value}</p>`
      );
    })
    .toString()
    .replaceAll(',', '');

  for (const item of items) {
    pProductItems += `
    <tr>
            <td>
            ${item.name}<br />
            ${customOptions}<br />
            </td>
            <td>
              ${item.sku}
            </td>
            <td>
              <img style="width:0.90in;height:0.90in" src=${cdnUrl(
                `catalog/product/${item.image}`,
                85
              )} alt="logo" />
            </td>
              <td>
              ${item.qty_ordered}
            </td>
              <td>
              ${merchantName}
            </td>
          </tr>   
          `;
  }
  return pProductItems;
};

const printOrderNote = (
  shipping_address: IOrders.IOrderDetail['extension_attributes']['shipping_address']
) => {
  let pNote;

  if (shipping_address && shipping_address.customer_note) {
    pNote = `<div>
    <span>Order Note:</span>
    <p>${shipping_address.customer_note}</p>
    </div>`;
    return pNote;
  }

  return '';
};

export const printPage = async (
  orderDetail: IOrders.IOrderDetail,
  merchantName: string,
  operationAllowed?: boolean
) => {
  const printWindow = window.open('', '_blank', 'height=800,width=1200');

  if (printWindow) {
    printWindow.document.write(
      `<html lang="en"><head><title>Order ID: ${orderDetail.order_id}</title>`
    );

    printWindow.document.write('<style>');
    printWindow.document.write(
      `
      body, table
      {
          font-family: Verdana, sans-serif;
          font-size: 10pt;
          width:850px;
          line-height: 1.2rem;
      }
      hr
      {
          margin-top: 2rem;
          margin-bottom: 2rem;
      }
      p
      {
      margin:0;
      overflow-wrap:break-word;
      }
      span 
      {
      font-weight:bold;
      }
     `
    );
    printWindow.document.write('</style>');
    printWindow.document.write('</head>');
    printWindow.document.write('<body>');
    printWindow.document.write(
      `<div>
    <div style="justify-content: space-between;display: flex;">
    ${printOrderDetail({ ...orderDetail, operationAllowed })}
    <div style="margin-top:0.5rem;">
    <img style="height:80px" src="https://www.joigifts.com/images/logo/joi-gifts.svg" alt="logo"/>
    </div>
    ${printShippingAddress(orderDetail?.extension_attributes?.shipping_address)}
    </div>
    <hr/>
    <div>
    <table style="width: 850px; text-align: left">
    <thead>
    <th>Products</th>
    <th>Sku</th>
    <th>Image</th>
    <th>Qty</th>
    <th>Merchant</th>
    </thead>
    <tbody style="vertical-align: top;">
    ${printProductItems(orderDetail.items, merchantName)}
    </tbody>
    </table></div>
    <hr/>
  
    ${printOrderNote(orderDetail?.extension_attributes?.shipping_address)}
    <div style="margin-top: 20rem; display: flex; justify-content: space-between">
    <span>Driver's Name</span>
    <span>Recipient Signature</span>
    </div>
   </div>`
    );
    printWindow.document.write('</body>');
    printWindow.document.write('</html>');
    printWindow.document.close();
    printWindow.print();
  }
};

export const printGiftMessageHtmlForApp = (
  orderDetail: IOrders.IOrderDetail
) => {
  const cleanMessage =
    orderDetail?.extension_attributes?.gift_message?.message?.replace(
      /\n{2,}/g,
      '<span style="margin-top: 8px;"></span>'
    );

  const html = `<html lang="en">
  <head>
    <title>Print Gift Message</title>
    <meta name="viewport" content="width=device-width, initial-scale=1, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
  <style>
  @import url('https://fonts.googleapis.com/css2?family=${
    orderDetail?.extension_attributes?.gift_message &&
    orderDetail?.extension_attributes?.gift_message?.font_family?.replace(
      /\s+/g,
      '+'
    )
  }');
  
        body {
          font-family: ${
            orderDetail?.extension_attributes?.gift_message.font_family
          }, sans-serif;
          font-size: 14px;
        }
        .container {
          display: flex;
          flex-direction: column;
          margin: 0 auto; 
          text-align: center; 
          align-items: center;
          justify-content: center;
          height: 100%;
          word-break: break-word;
        }
        
        .text {
          line-height: 1.3;
          white-space: pre-line;
        }
        
        .image {
          margin-top: 12px;
          width: 70px;
          height: 70px;
        }

        br {
          display: block;
          margin: 30px 0;
       }
       
        
       @page {
        size: A5 landscape;
       }
      </style>
      </head>

      <body>

      <div class="container">
    <div style="display: flex; flex-direction: column;" class="text">${
      cleanMessage || ''
    }</div>
    ${
      orderDetail?.extension_attributes?.gift_message?.qr_url_image
        ? `
    <img class="image" src="${orderDetail?.extension_attributes?.gift_message.qr_url_image}" alt="qr">
    <div style="font-family: sans-serif; color: #666; font-size:12px">
      <span>Scan to view your sender's message</span> &nbsp; · &nbsp; 
      <span dir="rtl">صوّر لعرض رسالة مرسل الهدية</span>
    </div>
    `
        : ''
    }
    </div>
    </body>
    </html>
    `;

  return html;
};

export const printGiftMessage = async (orderDetail: IOrders.IOrderDetail) => {
  const printWindow = window.open('', '_blank', 'height=600,width=800');

  if (printWindow) {
    printWindow.document.write(
      `<html lang="en"><head><title>Print Gift Message</title></head>`
    );
    printWindow.document.write('<style>');
    printWindow.document.write(
      `
        @import url('https://fonts.googleapis.com/css2?family=${
          orderDetail?.extension_attributes?.gift_message &&
          orderDetail?.extension_attributes?.gift_message?.font_family?.replace(
            /\s+/g,
            '+'
          )
        }');
        
        body
        {
          font-family: ${
            orderDetail?.extension_attributes?.gift_message?.font_family
          }, sans-serif;
          font-size:14px;
        }

        .container {
          display: flex;
          flex-direction: column;
          margin: 0 auto; 
          text-align: center; 
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 90%;
          word-break: break-word;
        }
        
        .text {
          line-height: 1.3;
          white-space: pre-line;
        }
        
        .image {
          width: 70px;
          height: 70px;
        }

        br {
          display: block;
          margin: 30px 0;
       }
        
        @page {
            size: A5 landscape;
        }
       `
    );
    printWindow.document.write('</style>');
    printWindow.document.write('</head>');

    const cleanMessage =
      orderDetail?.extension_attributes?.gift_message?.message?.replace(
        /\n{2,}/g,
        '<span style="margin-top: 8px;"></span>'
      );

    printWindow.document.write(`<div class="container">
    <div style="display: flex; flex-direction: column;" class="text">${
      cleanMessage || ''
    }</div>
    ${
      orderDetail?.extension_attributes?.gift_message?.qr_url_image
        ? `
    <img class="image" src="${orderDetail?.extension_attributes?.gift_message?.qr_url_image}" alt="qr">
    <div style="font-family: sans-serif; color: #666; font-size:12px">
      <span>Scan to view your sender's message</span> &nbsp; · &nbsp; 
      <span dir="rtl">صوّر لعرض رسالة مرسل الهدية</span>
    </div>
    `
        : ''
    }
   
  </div>`);

    printWindow.document.write('</html>');
    printWindow.document.close();
    printWindow.print();
  }
};
