import dayjs from 'dayjs';
import colors from 'styles/variables.scss';
import { OrderStatus } from './enums';
import { IInventory, IOrders } from '../models';

// eslint-disable-next-line no-unused-vars
const colorData = {
  Accepted: colors.green,
  Closed: colors.crayolaBlue,
  Delivered: colors.purple,
  'On Hold': colors.red,
  Processing: colors.orange,
  'Ready to Dispatch': colors.navyBlue,
  'Out For Delivery': colors.brNavyBlue,
};

export const deliveryDateTimeFormatter = (date: string) => {
  if (!date) return '';
  return dayjs(date).format('D MMMM YYYY');
};

export const deliveryMonthAndDayFormatter = (date: string) => {
  if (!date) return '';
  return dayjs(date).format('MMM D').toLocaleUpperCase();
};

export const deliveryTimeFormatter = (time: string) => {
  if (!time) return '';
  return time?.replace('_', ' - ');
};

export const getOrderStatusLabel = (status: OrderStatus): string => {
  // eslint-disable-next-line no-unused-vars
  const json: { [_ in OrderStatus]: string } = {
    accepted: 'Accepted',
    closed: 'Closed',
    complete: 'Delivered',
    holded: 'On Hold',
    processing: 'Processing',
    ready_to_ship: 'Ready To Dispatch',
    dispatched: 'Dispatched',
    out_for_delivery: 'Out For Delivery',
  };

  return json[status];
};

export const getStatusColorForOrderStatus = (status: string): string => {
  // @ts-ignore
  return colorData[status];
};

export function cdnUrl(path: string, width = 0, height = 0) {
  if (path.startsWith('http')) {
    return path;
  }

  const src = `${process.env.REACT_APP_CDN_URL}/${path.replace(/^\/+/g, '')}`;
  const srcSplit = src.split('com/');

  return `${srcSplit[0]}com/cache/${width}/${height}/${srcSplit[1]}`;
}

export const validateEmail = (mailAsString: string): boolean => {
  const mailAsArray = mailAsString.split(',');
  let invalidEmail = false;

  mailAsArray.forEach(mail => {
    if (!/^\S+@\S+\.\S+$/.test(mail)) {
      invalidEmail = true;
    }
  });

  return invalidEmail;
};

export const objectToQueryString: any = (
  obj: any,
  parentPrefix = 'searchCriteria'
) => {
  const parts = [];
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const prefix = `${parentPrefix}[${key}]`;
      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i += 1) {
          parts.push(objectToQueryString(obj[key][i], `${prefix}[${i}]`));
        }
      } else if (typeof obj[key] === 'object') {
        parts.push(objectToQueryString(obj[key], prefix));
      } else {
        parts.push(
          `${encodeURIComponent(prefix)}=${encodeURIComponent(obj[key])}`
        );
      }
    }
  }
  return parts.join('&');
};

export const createFilterObject: any = (
  filters?: IOrders.IFilter['filters'] | IInventory.IFilter['filters'] | object
) => {
  const filterTypeMap = {
    name: 'like',
    increment_id: 'like',
    sku: 'like',
    delivery_city: 'in',
    status: 'in',
  };

  return Object.keys(filters as object).map((key: string) => {
    // @ts-ignore
    let value = filters?.[key];
    let conditionType = 'in';

    const [filterKey, range] = key.split('__');
    const isRange = !!range;

    if (isRange) {
      conditionType = range;
      if (!['stock_qty', 'delivery_date'].includes(filterKey)) {
        value = `${value} ${range === 'from' ? '00:00:00' : '23:59:59'}`;
      }

      if (filterKey === 'delivery_date') {
        conditionType = range === 'from' ? 'gteq' : 'lteq';
      }
    } else {
      conditionType = (filterTypeMap as any)[filterKey] || conditionType;
    }

    if (conditionType === 'like') {
      value = `%${value}%`;
    }

    return {
      filters: [
        {
          field: filterKey,
          condition_type: conditionType,
          value,
        },
      ],
    };
  });
};
