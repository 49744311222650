import { Box, Modal, Typography } from '@mui/material';
import { Button } from 'components';
import {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useRef,
  useState,
} from 'react';

import ImgPlaceHolder from '../../../../assets/images/upload_placeholder.webp';

const style: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  backgroundColor: '#fff',
  padding: 24,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
};

interface IPhotoUpload {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  dispatchOrder: (base64: string) => Promise<void>;
}

const PhotoUpload: FC<IPhotoUpload> = ({ open, setOpen, dispatchOrder }) => {
  const [base64, setBase64] = useState<string | undefined>();

  const inputRef = useRef<HTMLInputElement>(null);

  const convertToBase64 = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files?.length) {
      const file = files[0];

      const base64 = await convertToBase64(file);

      if (typeof base64 === 'string') {
        setBase64(base64);
      }
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={style}>
        <Typography align="center" fontWeight={500}>
          Please upload a photo
        </Typography>
        <input
          hidden
          ref={inputRef}
          onChange={({ target }) => {
            handleFileUpload(target.files);
          }}
          type="file"
          accept=".jpeg, .png, .jpg"
        />
        <Box
          display="flex"
          justifyContent="center"
          onClick={() => inputRef.current?.click()}
        >
          <img width={170} alt="order_image" src={base64 || ImgPlaceHolder} />
        </Box>
        <Button
          disabled={!base64}
          onClick={() => {
            if (base64) {
              dispatchOrder(base64);
              setBase64(undefined);
            }
          }}
          fullWidth
          color="success"
          text="Upload"
          variant="contained"
        />
      </Box>
    </Modal>
  );
};

export default PhotoUpload;
