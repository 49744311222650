import { IOrders } from 'models';
import { endpoints } from './endpoints';
import httpRequest from './http-request';
import { createFilterObject, objectToQueryString } from '../lib/utils';

export const getAllOrdersRequest = (
  filters?: IOrders.IFilter
): Promise<IOrders.IResponse> => {
  // @ts-ignore
  const {
    // @ts-ignore
    currentPage,
    // @ts-ignore
    sortBy = 'created_at',
    // @ts-ignore
    sortDir = 'DESC',
    // @ts-ignore
    filters: filterList,
  } = filters;

  const filterGroups = createFilterObject(filterList);

  const query = {
    ...(currentPage && { currentPage }),
    pageSize: 50,
    ...(sortBy &&
      sortDir && { sortOrders: [{ field: sortBy, direction: sortDir }] }),
    filter_groups: filterGroups,
  };

  return httpRequest.get(`${endpoints.allOrder}?${objectToQueryString(query)}`);
};

export const getAllNewOrdersRequest = (
  filters?: IOrders.IFilter
): Promise<IOrders.IResponse> => {
  // @ts-ignore
  const {
    // @ts-ignore
    currentPage,
    // @ts-ignore
    sortBy = 'created_at',
    // @ts-ignore
    sortDir = 'DESC',
    // @ts-ignore
    filters: filterList,
  } = filters;

  const processedFilterList = { ...filterList, status: 'processing' };

  const query = {
    ...(currentPage && { currentPage }),
    ...(sortBy &&
      sortDir && { sortOrders: [{ field: sortBy, direction: sortDir }] }),
    pageSize: 50,
    filter_groups: createFilterObject(processedFilterList),
  };

  return httpRequest.get(`${endpoints.allOrder}?${objectToQueryString(query)}`);
};

export const getOrderDetail = (
  req: IOrders.IOrderDetailRequest
): Promise<IOrders.IOrderDetail> => {
  return httpRequest.get(endpoints.orderDetail + req.orderId);
};

export const rejectOrderRequest = (
  id: string,
  data: { reject_reason: string; reject_comment: string }
): Promise<boolean> => {
  return httpRequest.put(
    endpoints.rejectOrder + id,
    JSON.stringify({ ...data })
  );
};

export const acceptOrderRequest = (id: string): Promise<boolean> => {
  return httpRequest.put(endpoints.acceptOrder + id);
};

export const getCsvData = async (filters: IOrders.IFilter) => {
  const {
    sortBy = 'created_at',
    // @ts-ignore
    sortDir = 'DESC',
    filters: filterList,
  } = filters;

  const query = {
    pageSize: 1000,
    ...(sortBy &&
      sortDir && { sortOrders: [{ field: sortBy, direction: sortDir }] }),
    filter_groups: createFilterObject(filterList),
  };

  const data: any = await httpRequest.get(
    `${endpoints.csv}?${objectToQueryString(query)}`
  );

  const isDeliveryTime = (data?.titles || []).includes('Delivery Time');

  return [
    data.titles,
    ...data.items.map((csvDataItem: any) => [
      csvDataItem?.order_id,
      csvDataItem?.status,
      csvDataItem?.delivery_city,
      csvDataItem?.delivery_date,
      csvDataItem?.[isDeliveryTime ? 'delivery_time' : 'pickup_time'],
      csvDataItem?.cost_price,
      csvDataItem?.currency,
      csvDataItem?.purchase_date,
    ]),
  ];
};

export const getDeliveries = async (
  date: string
): Promise<Array<IOrders.IOrder>> => {
  return httpRequest.get(endpoints.deliveries + date);
};

export const dispatchOrderRequest = async ({
  itemId,
  base64,
}: {
  itemId: string;
  base64: string;
}) => {
  const body = {
    content: {
      base64_encoded_data: base64,
    },
  };

  return httpRequest.put(endpoints.completeOrder + itemId, body);
};

export const shipOrderRequest = ({
  vendorOrderId,
}: IOrders.IShipOrderRequest) => {
  return httpRequest.post(endpoints.ship(vendorOrderId));
};
