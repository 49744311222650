import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import colors from 'styles/variables.scss';
import { useAtomValue } from 'jotai';
import { contentLoading } from 'store';

import styles from './inner-loading-overlay.module.scss';

const InnerLoadingOverlay: FC = () => {
  const loading = useAtomValue(contentLoading);

  return (
    <div
      style={{ display: loading ? 'flex' : 'none' }}
      className={styles.innerLoadingOverlayContainer}
    >
      <CircularProgress style={{ color: colors.black }} />
    </div>
  );
};

export default InnerLoadingOverlay;
