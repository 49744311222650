import { MobileContainer, MobileHeader } from 'components';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { pathNames } from 'hooks/useRouter';
import PageList from './components/page-list';

const Account = () => {
  const props: any = {
    fontSize: 'medium',
  };

  return (
    <>
      <MobileHeader title="Account" />
      <MobileContainer>
        <PageList
          icon={<PersonOutlineOutlinedIcon {...props} />}
          pageName="Profile"
          path={pathNames.profile}
        />
        <PageList
          icon={<Inventory2OutlinedIcon {...props} />}
          pageName="Inventory"
          path={pathNames.inventory}
        />
        <PageList
          icon={<HeadsetMicOutlinedIcon {...props} />}
          pageName="Help"
          path={pathNames.help}
        />
        <PageList
          icon={<LogoutIcon color="error" {...props} />}
          pageName="Logout"
          path={pathNames.login}
        />
      </MobileContainer>
    </>
  );
};

export default Account;
