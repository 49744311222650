import { IUser } from 'models';
import { endpoints } from './endpoints';
import httpRequest from './http-request';

export const getCurrentUser = (): Promise<IUser.IUser> => {
  return httpRequest.get(endpoints.userInfo);
};

export const updateUser = async (changes: IUser.IUpdateRequest) => {
  try {
    await httpRequest.put(
      endpoints.updateProfile,
      JSON.stringify({ ...changes })
    );

    return true;
  } catch (e) {
    return null;
  }
};
