import { Typography } from '@mui/material';
import { FC } from 'react';
import colors from 'styles/variables.scss';

import styles from './price-row.module.scss';

interface IPriceRow {
  costPrice: string;
  // retailPrice: string;
  // totalAmount: string;
  currency: string;
}

const PriceRow: FC<IPriceRow> = ({
  currency,
  costPrice,
  // retailPrice,
  // totalAmount,
}) => {
  return (
    <div className={styles.priceRowContainer}>
      <div>
        <Typography fontSize="13px" color={colors.black50}>
          Cost Price
        </Typography>
        <Typography fontSize="1.125rem">
          {`${currency} ${costPrice}`}
        </Typography>
      </div>
      {/* <div>
        <Typography fontSize="13px" color={colors.black50}>
          Retail Price
        </Typography>
        <Typography fontSize="1.125rem">{`${currency} ${retailPrice}`}</Typography>
      </div>
      <div>
        <Typography fontSize="13px" color={colors.black50}>
          Total Amount
        </Typography>
        <Typography
          fontWeight="bold"
          fontSize="1.125rem"
        >{`${currency} ${totalAmount}`}</Typography>
      </div> */}
    </div>
  );
};

export default PriceRow;
