import { getAllProducts } from 'http-requests';
import { IInventory } from 'models';
import { useEffect, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { contentLoading } from 'store';

const useInventoryFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<IInventory.IFilter>(
    {} as IInventory.IFilter
  );
  const [tempFilters, setTempFilters] = useState<IInventory.IFilter>(
    {} as IInventory.IFilter
  );
  const [products, setProducts] = useState<IInventory.IResponse>(
    {} as IInventory.IResponse
  );

  const [loading, setLoading] = useAtom(contentLoading);

  const totalPage = Math.ceil(products.total_count / 50);

  const getProducts = async (filter: typeof filters) => {
    try {
      const response = await getAllProducts({ ...filter });
      setProducts(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setProducts({} as IInventory.IResponse);
    }
  };

  const getSearchParamsAsObject = () => {
    // @ts-ignore
    const currentParams = Object.fromEntries([...searchParams]);

    return {
      filters: { ...currentParams },
      currentPage: currentParams.currentPage,
      sortBy: currentParams.sortBy,
      sortDir: currentParams.sortDir as 'ASC' | 'DESC',
    };
  };

  const setSearchParamsToFilters = async () => {
    const newFilters = getSearchParamsAsObject();

    delete newFilters.filters.currentPage;
    delete newFilters.filters.sortBy;
    delete newFilters.filters.sortDir;

    setFilters({ ...newFilters, currentPage: +newFilters.currentPage });
    await getProducts({ ...newFilters, currentPage: +newFilters.currentPage });

    setTempFilters({ ...newFilters, currentPage: +newFilters.currentPage });
  };

  const applyTempFilters = (replace = false) => {
    const newFilters: IInventory.IFilter['filters'] = tempFilters.filters || {};

    Object.keys(newFilters).forEach(
      // @ts-ignore
      key => !newFilters[key].trim() && delete newFilters[key]
    );

    setSearchParams(
      {
        ...newFilters,
        currentPage: '1',
      } as unknown as URLSearchParamsInit,
      { replace }
    );
  };

  const addToTempFiltersForMobileSearch = (value: string) => {
    setTempFilters({
      filters: {
        ...tempFilters.filters,
        q: value,
        mobile: 'true',
      },
    });

    if (!value) {
      searchParams.delete('mobile');
      searchParams.delete('q');
      setSearchParams(searchParams, { replace: true });
    }
  };

  const addToTempFilters = (
    key: string,
    value: string,
    searchBar?: boolean
  ) => {
    setTempFilters({ filters: { ...tempFilters.filters, [key]: value } });

    if (key === 'sku' && value === '' && searchBar) {
      searchParams.delete('sku');
      setSearchParams(searchParams, { replace: true });
    }
  };

  const resetTempFilters = () => {
    setTempFilters({ ...filters });
  };

  const clearTempFilters = (apply?: boolean) => {
    setTempFilters({});

    if (apply) {
      setSearchParams(
        {
          currentPage: '1',
        } as unknown as URLSearchParamsInit,
        { replace: true }
      );
    }
  };

  const changePage = (page: number) => {
    searchParams.set('currentPage', page.toString());
    setSearchParams(searchParams);
  };

  const sortData = (key: string, sort: string) => {
    setLoading(true);
    searchParams.set('sortBy', key);
    searchParams.set('sortDir', sort.toUpperCase());

    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    setLoading(true);
    setSearchParamsToFilters();
  }, [searchParams]);

  return {
    products: products.items,
    tempFilters,
    clearTempFilters,
    resetTempFilters,
    addToTempFilters,
    applyTempFilters,
    addToTempFiltersForMobileSearch,
    loading,
    setLoading,
    totalPage,
    pageDetail: {
      currentPage: products?.search_criteria?.current_page,
      totalCount: products.total_count,
    },
    changePage,
    sortData,
    retrieve: setSearchParamsToFilters,
  };
};

export default useInventoryFilters;
