import { AlertColor } from '@mui/material';
import { atom } from 'jotai';

interface IAlert {
  severity: AlertColor;
  title: string;
  desc?: string;
}

export const alerts = atom<Array<IAlert>>([]);
export const contentLoading = atom<boolean>(false);
export const pageLoading = atom<boolean>(false);
