import { FC, useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import colors from 'styles/variables.scss';
import { getStatusColorForOrderStatus } from 'lib/utils';
import { OrderStatus } from 'lib/enums';
import { pathNames } from 'hooks/useRouter';

import styles from './mobile-header.module.scss';

interface IAllOrderProps {
  backButton?: boolean;
  closeButton?: boolean;
  closeButtonAction?: () => void;
  title?: string;
  desc?: string;
  orderStatus?: OrderStatus;
  rightIconType?: 'filter' | 'clear';
  rightIconAction?: () => void;
  withSearchBar?: boolean;
  borderless?: boolean;
  backPagePath?: string;
}

const PADDING_HORIZONTAL = 32;

const MobileHeader: FC<IAllOrderProps> = ({
  backButton,
  title,
  rightIconType,
  withSearchBar,
  closeButton,
  closeButtonAction,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rightIconAction = () => {},
  desc,
  orderStatus,
  borderless,
  backPagePath,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // @ts-ignore
  const allSearchParams = Object.fromEntries([...searchParams]);

  const showBadge = Object.keys(allSearchParams)?.length > 1; // > 1 because of curPage should be missing

  const [width, setWidth] = useState<number>(
    window.innerWidth - PADDING_HORIZONTAL
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth - PADDING_HORIZONTAL);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goBack = () => {
    if (backPagePath) {
      navigate(backPagePath);
      return;
    }

    if (location.key === 'default') {
      navigate(pathNames.newOrders);
      return;
    }

    navigate(-1);
  };

  return (
    <div
      className={`${styles.mobileHeaderContainer} ${
        withSearchBar || borderless ? styles.borderless : ''
      }`}
      style={{ width }}
    >
      <div>
        {backButton && <ArrowBackIosIcon fontSize="medium" onClick={goBack} />}
        {closeButton && (
          <CloseIcon fontSize="medium" onClick={closeButtonAction} />
        )}
      </div>
      <div className={styles.midSide}>
        {title && <Typography fontWeight={500}>{title}</Typography>}
        {desc && (
          <Typography fontSize="13px" color={colors.black50}>
            {desc}{' '}
            {orderStatus && (
              <Typography
                color={getStatusColorForOrderStatus(orderStatus)}
                display="inline"
                fontSize="13px"
              >
                · {orderStatus}
              </Typography>
            )}
          </Typography>
        )}
      </div>
      <div onClick={() => rightIconAction()} className={styles.rightSide}>
        {!rightIconType ? (
          ''
        ) : rightIconType === 'clear' ? (
          'Clear all'
        ) : (
          <div style={{ position: 'relative' }}>
            {showBadge && <div className={styles.filterBadge} />}
            <FilterAltOutlinedIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileHeader;
