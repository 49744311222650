import { memo, useState } from 'react';
import { Button, CustomPagination, TopTab } from 'components';
import { IOrders } from 'models';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import { pathNames } from 'hooks/useRouter';
import { useFilters } from 'hooks';
import { getCsvData } from 'http-requests';
import { useAtomValue } from 'jotai';
import { currentUser } from 'store';
import Filter from './components/filter/filter';

import styles from './all-orders.module.scss';

const AllOrders = () => {
  const { orders, pageDetail, loading, changePage, sortData, filters } =
    useFilters();

  const user = useAtomValue(currentUser);

  const [csvData, setCsvData] = useState<string>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const columns: Array<GridColDef> = [
    {
      field: 'order_increment_id',
      headerName: 'Order ID',
      flex: 0.6,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'created_at',
      headerName: 'Purchase Date',
      flex: 0.5,
      disableColumnMenu: true,
    },
    {
      field: 'cost_price',
      headerName: 'Cost Price',
      sortable: false,
      disableColumnMenu: true,
      renderCell(params: any) {
        if (!params?.row?.items) {
          return null;
        }

        return params.row.items.reduce(
          (acc: number, { cost = 0, qty_ordered = 1 }) =>
            acc + cost * qty_ordered,
          0
        );
      },
      flex: 0.3,
    },
    {
      field: 'delivery_city',
      renderCell(params: any) {
        if (!params?.row) {
          return null;
        }

        return params.row.extension_attributes.delivery_city;
      },
      headerName: 'Delivery City',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
    },
    {
      field: 'delivery_date',
      renderCell(params: any) {
        if (!params?.row) {
          return null;
        }

        return params.row.extension_attributes.delivery_date;
      },
      headerName: 'Delivery Date',
      flex: 0.4,
      disableColumnMenu: true,
    },
    {
      field:
        user?.custom_attributes?.find(
          ({ attribute_code }) => attribute_code === 'operation_allowed'
        )?.value === '1'
          ? 'delivery_time'
          : 'pickup_time',
      renderCell(params: any) {
        if (!params?.row) {
          return null;
        }

        return user?.custom_attributes?.find(
          ({ attribute_code }) => attribute_code === 'operation_allowed'
        )?.value === '1'
          ? params.row.extension_attributes.delivery_time
          : params.row.extension_attributes.pickup_time;
      },
      headerName:
        user?.custom_attributes?.find(
          ({ attribute_code }) => attribute_code === 'operation_allowed'
        )?.value === '1'
          ? 'Delivery Time'
          : 'Pick Up Time',
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.3,
      disableColumnMenu: true,
    },
    {
      field: 'action',
      flex: 0.2,
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        if (!params?.row) {
          return null;
        }

        return (
          <Button
            onClick={() => {
              window.open(
                pathNames.order.replace(
                  ':orderId',
                  params.row.merchant_order_id.toString()
                ),
                '_blank'
              );
            }}
            text="View"
            color="info"
            style={{ marginLeft: -10 }}
          />
        );
      },
    },
  ];

  const downloadAsCsv = async () => {
    try {
      setIsDownloading(true);
      const csvData: any = await getCsvData(filters);
      setCsvData(csvData);

      setTimeout(() => {
        document.getElementById('csvDownload')?.click();
      }, 1000);

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  return (
    <div className={styles.allOrdersContainer}>
      <div className={styles.buttons}>
        <Button
          onClick={() => setOpenFilter(prev => !prev)}
          text="Filters"
          variant="contained"
          color="info"
          startIcon={<FilterAltOutlinedIcon />}
        />
        <Button
          disabled={isDownloading}
          text={isDownloading ? 'Downloading' : 'Export'}
          variant="contained"
          color="info"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => downloadAsCsv()}
        />
      </div>
      {openFilter && <Filter setOpenFilter={setOpenFilter} />}

      <TopTab />

      <DataGrid
        sx={{
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        hideFooter
        onSortModelChange={model => {
          if (model.length === 0) return sortData('created_at', 'desc');

          return sortData(model[0]?.field, model[0]?.sort || 'asc');
        }}
        getRowId={(row: IOrders.IOrder) => row.order_id}
        loading={loading}
        rows={loading ? [] : orders}
        columns={columns}
        rowCount={pageDetail.totalCount}
        localeText={{
          noRowsLabel: 'No orders',
        }}
      />

      {orders.length > 0 && (
        <CustomPagination
          change={changePage}
          currentPage={pageDetail.currentPage}
          totalCount={pageDetail.totalCount}
        />
      )}

      {csvData && (
        <CSVLink
          id="csvDownload"
          filename={`${new Date()}-orders.csv`}
          data={csvData}
        />
      )}
    </div>
  );
};

export default memo(AllOrders);
