import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { Button } from 'components';
import { useFilters } from 'hooks';
import { Cities } from 'lib/enums';
import styles from './filter.module.scss';
import PurchaseDateFilter from '../../../../../components/DateFilters/PurchaseDateFilter';
import DeliveryDateFilter from '../../../../../components/DateFilters/DeliveryDateFilter';

interface IFilter {
  setOpenFilter: Dispatch<SetStateAction<boolean>>;
}

const Filter: FC<IFilter> = ({ setOpenFilter }) => {
  const {
    addToTempFilters,
    tempFilters,
    applyTempFilters,
    clearTempFilters,
    filters,
  } = useFilters(true);

  const largeInput: SxProps = {
    width: 300,
  };

  const cityData = Object.values(Cities);

  return (
    <div className={styles.filterContainer}>
      <Typography variant="h5" fontWeight="bold">
        Filters
      </Typography>

      <div className={styles.rowContainer}>
        <TextField
          value={tempFilters.filters?.increment_id || ''}
          onChange={e => addToTempFilters('increment_id', e.target.value)}
          sx={{ ...largeInput }}
          label="Order Id"
        />

        <PurchaseDateFilter />

        {/* <TextField
            value={tempFilters.filters?.['grand_total.from'] || ''}
            onChange={e => addToTempFilters('grand_total.from', e.target.value)}
            label="Grand Total"
            type="number"
          />
          <Typography>to</Typography>
          <TextField
            value={tempFilters.filters?.['grand_total.to'] || ''}
            onChange={e => addToTempFilters('grand_total.to', e.target.value)}
            label="Grand Total"
            type="number"
          /> */}

        <FormControl sx={{ ...largeInput }}>
          <InputLabel id="city-select-label">Delivery City</InputLabel>
          <Select
            multiple
            labelId="city-select-label"
            id="city-select"
            variant="outlined"
            value={tempFilters.filters?.delivery_city?.split(',') || []}
            onChange={({ target: { value } }) => {
              let cities = typeof value === 'string' ? value.split(',') : value;
              cities = cities.filter(city => city.trim() !== '');

              addToTempFilters('delivery_city', cities.toString());
            }}
            renderValue={selected => selected.join(', ')}
            label="Delivery City"
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          >
            {cityData
              .sort(a =>
                filters?.filters?.delivery_city?.includes(a) ? -1 : 1
              )
              .map(city => (
                <MenuItem key={city} value={city}>
                  <Checkbox
                    checked={
                      tempFilters?.filters?.delivery_city?.includes(city) ||
                      false
                    }
                  />
                  <ListItemText primary={city} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className={styles.rowContainer}>
        <DeliveryDateFilter />

        <TextField
          value={tempFilters.filters?.sku || ''}
          onChange={e => addToTempFilters('sku', e.target.value)}
          label="SKU"
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => clearTempFilters(true)}
          variant="contained"
          color="inherit"
          text="Clear"
        />

        <Button
          onClick={() => {
            applyTempFilters();
            setOpenFilter(false);
          }}
          variant="contained"
          color="info"
          text="Apply Filters"
        />
      </div>
    </div>
  );
};

export default Filter;
