import {
  FormControl,
  IconButton,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';
import { FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './mobile-search-bar.module.scss';

interface ISearchBar extends Omit<StandardTextFieldProps, 'variant'> {
  // eslint-disable-next-line no-unused-vars
  handleChange: (value: string) => void;
  action: () => void;
}

const SearchBar: FC<ISearchBar> = ({ ...props }) => {
  return (
    <div className={styles.searchBarContainer}>
      <div className={styles.searchBarInnerContainer}>
        <SearchIcon color="disabled" />
        <FormControl sx={{ width: '100%' }}>
          <TextField
            value={props.value}
            onChange={({ target }) => {
              props.handleChange(target.value);
            }}
            sx={{ input: { justifyContent: 'center' } }}
            variant="standard"
            size="small"
            fullWidth
            onKeyDown={ev => {
              if (ev.key === 'Enter') {
                props.action();
              }
            }}
            placeholder="Search"
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              disableUnderline: true,
              style: { textAlign: 'center' },
              ...(props.value !== '' && {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      props.handleChange('');
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }),
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default SearchBar;
