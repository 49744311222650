import { Divider, Typography } from '@mui/material';
import { FC } from 'react';
import colors from 'styles/variables.scss';

import styles from './account-information-row.module.scss';

interface IAccountInformationRow {
  title: string;
  value: string;
  border?: boolean;
}

const AccountInformationRow: FC<IAccountInformationRow> = ({
  title,
  value,
  border,
}) => {
  return (
    <>
      <div className={styles.accountInformationRowContainer}>
        <div className={styles.item}>
          <Typography variant="body2" fontWeight={500}>
            {title}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography variant="body2" color={colors.black50}>
            {value}
          </Typography>
        </div>
      </div>
      {border && <Divider />}
    </>
  );
};

export default AccountInformationRow;
