import { Button, MobileContainer, MobileHeader } from 'components';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderDetail, shipOrderRequest } from 'http-requests';
import { IOrders } from 'models';
import { deliveryDateTimeFormatter } from 'lib/utils';
import { alerts, contentLoading, currentUser } from 'store';
import { Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import colors from 'styles/variables.scss';
import ShipPopup from 'pages/web/order-detail/ship-popup/ship-popup';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { printGiftMessageHtmlForApp } from 'pages/web/order-detail/print-helper';
import ImageModal from 'components/modals/image-modal';
import Card from './components/card/card';
import DeliveryCard from './components/delivery-card/delivery-card';
import styles from './order-detail.module.scss';

const OrderDetail: FC = () => {
  const { orderId } = useParams();

  const alertArray = useSetAtom(alerts);
  const user = useAtomValue(currentUser);

  const operationAllowed = useMemo(() => {
    const operationAllowedAttribute = user?.custom_attributes?.find(
      ({ attribute_code }) => attribute_code === 'operation_allowed'
    );

    return operationAllowedAttribute?.value === '1';
  }, [user]);

  const [loading, setLoading] = useAtom(contentLoading);
  const [orderDetail, setOrderDetail] = useState<IOrders.IOrderDetail>(
    {} as IOrders.IOrderDetail
  );
  const [shipPopupOpen, setShipButtonOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const getOrder = async () => {
    try {
      if (orderId) {
        const response = await getOrderDetail({
          orderId: +orderId,
        });

        setOrderDetail(response);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const shipOrder = async () => {
    try {
      setLoading(true);
      setShipButtonOpen(false);

      const response = await shipOrderRequest({
        vendorOrderId: orderDetail.merchant_order_id,
      });

      if (response) {
        setShipButtonOpen(false);

        alertArray(prev => [
          ...prev,
          { severity: 'success', title: 'The order status has been changed' },
        ]);

        getOrder();
      } else {
        setShipButtonOpen(false);
      }
    } catch (error: any) {
      setLoading(false);
      setShipButtonOpen(false);
      alertArray(prev => [
        ...prev,
        { severity: 'error', title: error.response?.data.message },
      ]);
    }
  };

  const printMessage = () => {
    if (window.inApp) {
      const message = printGiftMessageHtmlForApp(orderDetail);

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          channel: 'print',
          message,
        })
      );
    } else {
      // eslint-disable-next-line no-alert
      alert('Please use our mobile app or desktop');
    }
  };

  useEffect(() => {
    if (orderDetail?.extension_attributes?.gift_message?.font_family) {
      const css = `
        @import url('https://fonts.googleapis.com/css2?family=${orderDetail?.extension_attributes?.gift_message?.font_family.replace(
          /\s+/g,
          '+'
        )}');
        `;
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.appendChild(document.createTextNode(css));

      head.appendChild(style);
    }
  }, [orderDetail]);

  useEffect(() => {
    setLoading(true);
    getOrder();
  }, []);

  return (
    <>
      <ImageModal
        open={!!imageUrl}
        onClose={() => setImageUrl('')}
        imageUrl={imageUrl}
      />

      <MobileHeader
        backButton
        title={orderDetail.order_increment_id}
        desc={deliveryDateTimeFormatter(orderDetail.created_at) || ''}
        orderStatus={orderDetail.status}
      />
      <MobileContainer withoutBottomTab>
        {orderDetail?.items?.map((order, index) => (
          <Card
            key={index}
            {...order}
            currency={orderDetail?.order_currency_code}
            orderStatus={orderDetail.status}
            disable={loading}
            canShip={orderDetail?.extension_attributes?.can_ship}
            getOrder={getOrder}
            onClickImage={imageUrl => setImageUrl(`catalog/product${imageUrl}`)}
          />
        ))}
        <DeliveryCard {...orderDetail} operationAllowed={operationAllowed} />
        {orderDetail?.extension_attributes?.gift_message && (
          <div className={styles.giftMessageContainer}>
            <Typography variant="body2" color={colors.black50}>
              Gift Message
            </Typography>
            <Typography
              fontFamily={
                orderDetail?.extension_attributes?.gift_message?.font_family ||
                undefined
              }
              component="div"
              variant="body2"
            >
              {orderDetail?.extension_attributes?.gift_message?.message}

              {orderDetail?.extension_attributes?.gift_message
                ?.qr_url_image && (
                <Typography
                  sx={{ mt: 1 }}
                  variant="body2"
                  color={colors.navyBlue}
                >
                  This gift message contains a QR code
                </Typography>
              )}
            </Typography>
            {(orderDetail?.extension_attributes?.gift_message?.message ||
              orderDetail?.extension_attributes?.gift_message.qr_url_image) && (
              <Button
                text="Print"
                fullWidth
                variant="contained"
                onClick={printMessage}
                sx={{ mt: 2 }}
                color="success"
              />
            )}
          </div>
        )}
        <div style={{ height: 1 }} />
        {orderDetail?.extension_attributes?.can_ship && (
          <div className={styles.shipContainer}>
            <Button
              startIcon={<LocalShippingOutlinedIcon />}
              variant="contained"
              color="success"
              text="Ship"
              fullWidth
              onClick={() => setShipButtonOpen(true)}
            />
          </div>
        )}
        <ShipPopup
          ship={shipOrder}
          open={shipPopupOpen}
          setOpen={setShipButtonOpen}
        />
      </MobileContainer>
    </>
  );
};

export default OrderDetail;
