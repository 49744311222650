import { IInventory } from 'models';
import { createFilterObject, objectToQueryString } from 'lib/utils';
import { endpoints } from './endpoints';
import httpRequest from './http-request';

export const getAllProducts = (
  filters: IInventory.IFilter
): Promise<IInventory.IResponse> => {
  const {
    currentPage,
    sortBy,
    sortDir,
    filters: { q: searchQuery, mobile, ...filterList },
  }: any = filters;

  const isMobileSearch = !!mobile;

  const filterGroups = createFilterObject(filterList);

  // If both sku and name filters are present, merge them into one filter group
  // for mobile search input
  if (isMobileSearch && searchQuery) {
    let skuIndex = filterGroups.findIndex((group: any) =>
      group.filters.some((filter: any) => filter.field === 'sku')
    );

    let nameIndex = filterGroups.findIndex((group: any) =>
      group.filters.some((filter: any) => filter.field === 'name')
    );

    if (skuIndex !== -1) {
      filterGroups[skuIndex].filters[0].value = `%${searchQuery}%`;
    } else {
      filterGroups.push({
        filters: [
          {
            field: 'sku',
            value: `%${searchQuery}%`,
            condition_type: 'like',
          },
        ],
      });

      skuIndex = filterGroups.length - 1;
    }

    if (nameIndex !== -1) {
      filterGroups[nameIndex].filters[0].value = `%${searchQuery}%`;
    } else {
      filterGroups.push({
        filters: [
          {
            field: 'name',
            value: `%${searchQuery}%`,
            condition_type: 'like',
          },
        ],
      });

      nameIndex = filterGroups.length - 1;
    }

    if (skuIndex !== -1 && nameIndex !== -1 && skuIndex !== nameIndex) {
      const nameFilter = filterGroups[nameIndex].filters.find(
        (filter: any) => filter.field === 'name'
      );

      filterGroups[skuIndex].filters.push(nameFilter);
      filterGroups.splice(nameIndex, 1);
    }
  }

  const query = {
    currentPage: currentPage || 1,
    pageSize: 50,
    ...(sortBy &&
      sortDir && { sortOrders: [{ field: sortBy, direction: sortDir }] }),
    filter_groups: [
      {
        filters: [
          {
            field: 'status',
            value: '1',
            condition_type: 'eq',
          },
        ],
      },
      ...filterGroups,
    ],
  };

  return httpRequest.get(`${endpoints.products}?${objectToQueryString(query)}`);
};

export const getProductDetail = (
  data: IInventory.IDetailRequest
): Promise<IInventory.IProductDetail> => {
  return httpRequest.get(`${endpoints.productDetail}/${data.productId}`);
};

export const updateStock = ({
  productId,
  qty,
}: IInventory.IStockUpdateRequest): any => {
  return httpRequest.put(endpoints.updateStock(productId, qty));
};
