import { getAllNewOrdersRequest, getAllOrdersRequest } from 'http-requests';
import { useAtom } from 'jotai';
import { IOrders } from 'models';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { contentLoading, selectedFilters, selectedTempFilters } from 'store';
import { OrderStatus } from 'lib/enums';
import { pathNames } from './useRouter';
import { DateTime } from 'luxon';

const useFilters = (withoutRequest?: boolean | undefined) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState<IOrders.IResponse>(
    {} as IOrders.IResponse
  );
  const [filters, setSelectedFilters] = useAtom(selectedFilters);
  const [tempFilters, setTempFilters] = useAtom(selectedTempFilters);
  const [loading, setLoading] = useAtom(contentLoading);

  const isNewOrders = pathname === pathNames.newOrders;
  const totalPage = Math.ceil(orders.total_count / 50);

  const getOrders = async (filter: IOrders.IFilter) => {
    try {
      const response = isNewOrders
        ? await getAllNewOrdersRequest({ ...filter })
        : await getAllOrdersRequest({ ...filter });

      setOrders({
        ...response,
      });
      setLoading(false);
    } catch (error) {
      setOrders({} as IOrders.IResponse);
      setLoading(false);
    }
  };

  const getSearchParamsAsObject = () => {
    // @ts-ignore
    const currentParams = Object.fromEntries([...searchParams]);

    return {
      filters: { ...currentParams },
      currentPage: currentParams.currentPage,
      sortBy: currentParams.sortBy,
      sortDir: currentParams.sortDir as 'ASC' | 'DESC',
    };
  };

  const getSearchParams = async () => {
    const newFilters = getSearchParamsAsObject();

    delete newFilters.filters.currentPage;
    delete newFilters.filters.sortBy;
    delete newFilters.filters.sortDir;

    delete newFilters.filters.curPage;

    if (
      !searchParams.get('currentPage') ||
      searchParams.get('currentPage') === '0'
    ) {
      searchParams.set('currentPage', '1');

      setSearchParams(searchParams, { replace: true });
      return;
    }

    setSelectedFilters({ ...newFilters, currentPage: +newFilters.currentPage });
    setTempFilters({ ...newFilters, currentPage: +newFilters.currentPage });
    getOrders({ ...newFilters, currentPage: +newFilters.currentPage });
  };

  const addToTempFilters = (key: string, value: string) => {
    if (key === 'deliveryCity' && value === '') {
      const clearCities = tempFilters.filters;
      delete clearCities?.delivery_city;
      setTempFilters({ filters: { ...clearCities } });
      return;
    }

    const obj = { [key]: value };

    setTempFilters({
      // @ts-ignore
      filters: { ...tempFilters.filters, ...obj },
    });
  };

  const applyTempFilters = () => {
    const newFilters: IOrders.IFilter['filters'] = tempFilters.filters || {};

    Object.keys(newFilters).forEach(
      // @ts-ignore
      key => !newFilters[key].trim() && delete newFilters[key]
    );

    setSearchParams({ ...newFilters, currentPage: '1' });
  };

  const clearTempFilters = (apply?: boolean) => {
    setTempFilters({ filters: {} });

    if (apply) {
      setSearchParams({ currentPage: '1' });
    }
  };

  const resetTempFilters = () => {
    setTempFilters({ ...filters });
  };

  const convertAndAddDateToTempFilters = (key: string, date: string | null) => {
    addToTempFilters(
      key,
      date ? DateTime.fromISO(date)?.toFormat('yyyy-MM-dd') : ''
    );
  };

  const addQuickFiltersToFilters = (status: OrderStatus) => {
    const statuses = searchParams.get('status')?.split(',') || [];

    if (!statuses?.includes(status)) {
      searchParams.set('status', [...statuses, status].toString());
    } else {
      const removedStatus = statuses?.filter(s => s !== status) || [];

      if (removedStatus?.length === 0) {
        searchParams.delete('status');
      } else {
        searchParams.set('status', removedStatus?.toString());
      }
    }

    searchParams.set('currentPage', '1');
    setSearchParams(searchParams);
  };

  const checkQuickFilterIsInTheFilters = (status: OrderStatus) => {
    return filters.filters?.status?.includes(status);
  };

  const addQuickFiltersToTemp = (status: OrderStatus) => {
    const statuses = tempFilters.filters?.status?.split(',') || [];
    let newStatus: string[] = [];

    if (!statuses?.includes(status)) {
      newStatus = [...statuses, status];
    } else {
      newStatus = statuses?.filter(s => s !== status) || [];
    }

    setTempFilters(prev => ({
      ...prev,
      filters: { ...prev.filters, status: newStatus.toString() },
    }));
  };

  const checkQuickFilterIsInTheTemp = (status: OrderStatus) => {
    return tempFilters.filters?.status?.includes(status);
  };

  const changePage = (page: number) => {
    searchParams.set('currentPage', page.toString());
    setSearchParams(searchParams);
  };

  const sortData = (key: string, sort: string) => {
    setLoading(true);
    searchParams.set('sortBy', key);
    searchParams.set('sortDir', sort.toUpperCase());

    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    if (withoutRequest) {
      return;
    }

    setLoading(true);
    getSearchParams();
  }, [searchParams]);

  return {
    orders: orders?.items || [],
    addToTempFilters,
    applyTempFilters,
    convertAndAddDateToTempFilters,
    checkQuickFilterIsInTheFilters,
    checkQuickFilterIsInTheTemp,
    addQuickFiltersToFilters,
    addQuickFiltersToTemp,
    tempFilters,
    clearTempFilters,
    resetTempFilters,
    pageDetail: {
      currentPage: orders?.search_criteria?.current_page,
      totalCount: orders.total_count,
    },
    changePage,
    loading,
    totalPage,
    sortData,
    filters,
  };
};

export default useFilters;
