import { FC, useState } from 'react';
import { saveAs } from 'file-saver';
import { useSetAtom } from 'jotai';
import { Divider, Typography } from '@mui/material';
import PhotoUpload from 'pages/web/order-detail/photo-upload/photo-upload';
import { RejectPopup, Button, DescriptionModal } from 'components';
import { OrderStatus } from 'lib/enums';
import { cdnUrl } from 'lib/utils';
import { IOrders } from 'models';
import { alerts, contentLoading } from 'store';
import {
  acceptOrderRequest,
  dispatchOrderRequest,
  rejectOrderRequest,
  updateStock,
} from 'http-requests';
import colors from 'styles/variables.scss';
import PriceRow from './price-row/price-row';
import styles from './card.module.scss';
import Actions from './actions/actions';

interface ICard extends IOrders.IOrderDetailItem {
  orderStatus: OrderStatus;
  getOrder: () => void;
  disable?: boolean;
  currency: string;
  canShip: boolean;
  // eslint-disable-next-line no-unused-vars
  onClickImage: (imageUrl: string) => void;
}

const Card: FC<ICard> = ({ ...props }) => {
  const {
    item_id,
    product_id,
    sku,
    name,
    description,
    image,
    cost = 0,
    status,
    qty_ordered,
    currency,
    orderStatus,
    disable,
    canShip,
    onClickImage,
    getOrder,
    // * NOTE: No such data is received as props, but it is used in the component
    custom_options = [],
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [photoUploadOpen, setPhotoUploadOpen] = useState<boolean>(false);
  const [openDescriptionModal, setOpenDescriptionModal] =
    useState<boolean>(false);
  const alertArray = useSetAtom(alerts);
  const setLoading = useSetAtom(contentLoading);

  const changeAppBadgeCount = () => {
    if (window.deviceToken || window.inApp) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          channel: 'badge',
        })
      );
    }
  };

  const acceptOrder = async () => {
    try {
      setLoading(true);
      const response = await acceptOrderRequest(item_id.toString());

      if (response) {
        alertArray(prev => [
          ...prev,
          { severity: 'success', title: 'Order accepted!' },
        ]);
        changeAppBadgeCount();
        getOrder();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const reject = async (reason: string, detail: string) => {
    try {
      setLoading(true);
      setOpenModal(false);

      await rejectOrderRequest(item_id.toString(), {
        reject_reason: reason,
        reject_comment: detail,
      });

      if (reason === 'product_oos') {
        await updateStock({ productId: product_id, qty: 0 });
      }

      changeAppBadgeCount();
      getOrder();
    } catch (error) {
      setLoading(false);
      alertArray(prev => [
        ...prev,
        { severity: 'error', title: 'Something went wrong!' },
      ]);
    }
  };

  const dispatchOrder = async (base64: string) => {
    setLoading(true);
    setPhotoUploadOpen(false);

    try {
      if (base64) {
        const response = await dispatchOrderRequest({
          itemId: item_id.toString(),
          base64,
        });

        if (response) {
          getOrder();
        } else {
          alertArray(prev => [
            ...prev,
            { severity: 'error', title: 'Something went wrong!' },
          ]);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      alertArray(prev => [
        ...prev,
        { severity: 'error', title: 'Something went wrong!' },
      ]);
    }
  };

  const downloadImage = (url: string) => {
    if (window.deviceToken || window.inApp) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          channel: 'download',
          url,
        })
      );
      return;
    }

    saveAs(url, item_id.toString());
  };

  const showButtons =
    // @ts-ignore
    orderStatus === 'Processing' || orderStatus === 'Accepted';

  return (
    <>
      <DescriptionModal
        open={openDescriptionModal}
        onClose={() => setOpenDescriptionModal(false)}
        description={description}
      />

      <div className={styles.cardContainer}>
        <div className={styles.productDetailContainer}>
          <div
            onClick={event => {
              event.stopPropagation();
              onClickImage(image);
            }}
          >
            <img
              width={116}
              height={136}
              alt={name}
              src={cdnUrl(`catalog/product/${image}`, 116, 136)}
            />
          </div>
          <div>
            <Typography fontWeight="bold">{name}</Typography>
            <Typography variant="body2">SKU: {sku}</Typography>
            <Button
              variant="text"
              text="Product Detail"
              size="small"
              className={styles.detailButton}
              onClick={() => setOpenDescriptionModal(true)}
            />
          </div>
        </div>

        {custom_options.length !== 0 && (
          <div className={styles.customOptionsContainer}>
            <Typography fontWeight={500}>Custom Options</Typography>
            {custom_options.map(option => (
              <div key={option.key} style={{ display: 'flex' }}>
                <Typography variant="body2" fontWeight={500}>
                  {`${option.title}:`}
                </Typography>
                {option.value.startsWith('https://') ? (
                  <Typography
                    onClick={() => downloadImage(option.value)}
                    sx={{
                      ml: 1,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      wordWrap: 'break-word',
                    }}
                    variant="body2"
                    color={colors.navyBlue}
                  >
                    Click to download
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      ml: 1,
                      wordBreak: 'break-word',
                    }}
                    variant="body2"
                    color={colors.black50}
                    dangerouslySetInnerHTML={{ __html: option.value }}
                  />
                )}
              </div>
            ))}
          </div>
        )}

        <div className={styles.statusRow}>
          <Typography variant="body2" fontWeight={500} sx={{ flex: 0.5 }}>
            Quantity
          </Typography>
          <Typography variant="body2" color={colors.black50} sx={{ flex: 1 }}>
            {qty_ordered}
          </Typography>
        </div>
        <Divider />

        <div className={styles.statusRow}>
          <Typography variant="body2" fontWeight={500} sx={{ flex: 0.5 }}>
            Item Status
          </Typography>
          <Typography variant="body2" sx={{ flex: 1 }}>
            {status}
          </Typography>
        </div>
        <Divider />
        <PriceRow
          costPrice={(+cost * +qty_ordered).toString()}
          currency={currency}
        />
        {/* @ts-ignore */}
        {((orderStatus === 'Processing' && status === 'New') ||
          (status === 'Accepted' && !canShip && showButtons)) &&
          /* @ts-ignore */
          orderStatus !== 'On Hold' && (
            <Actions
              acceptOrder={acceptOrder}
              setOpenModal={setOpenModal}
              disable={disable}
              /* @ts-ignore */
              isConfirmFlow={orderStatus === 'Processing' && status === 'New'}
              setPhotoUploadOpen={setPhotoUploadOpen}
            />
          )}

        <RejectPopup reject={reject} open={openModal} setOpen={setOpenModal} />
        <PhotoUpload
          dispatchOrder={dispatchOrder}
          open={photoUploadOpen}
          setOpen={setPhotoUploadOpen}
        />
      </div>
    </>
  );
};

export default Card;
