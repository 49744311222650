import { FC } from 'react';
import { Divider, Link, Typography, TypographyProps } from '@mui/material';
import colors from 'styles/variables.scss';
import styles from './row.module.scss';

const subTextProps: TypographyProps = {
  variant: 'body2',
  color: colors.black50,
};

const textProps: TypographyProps = {
  variant: 'body2',
  fontWeight: 500,
};

const Row: FC<{
  title: string;
  value: string;
  borderless?: boolean;
  phone?: boolean;
}> = ({ title, value, borderless, phone }) => {
  return (
    <>
      <div className={styles.row}>
        <Typography className={styles.row} {...textProps}>
          {title}
        </Typography>
        {phone && !!value ? (
          <Link href={`tel:${value}`}>
            <Typography {...subTextProps} color={undefined}>
              {value}
            </Typography>
          </Link>
        ) : (
          <Typography
            style={{ wordWrap: 'break-word', width: '70%' }}
            {...subTextProps}
          >
            {value || '-'}
          </Typography>
        )}
      </div>
      {!borderless && <Divider sx={{ mt: 1, mb: 1 }} />}
    </>
  );
};

export default Row;
