import { IAuth } from 'models';
import { endpoints } from './endpoints';
import httpRequest from './http-request';

export const loginRequest = (
  data: IAuth.ILoginRequest
): Promise<IAuth.IToken> => {
  return httpRequest.post(endpoints.login, JSON.stringify(data));
};

export const registerDevice = () => {
  return httpRequest.post(endpoints.registerDevice + window.deviceToken);
};

export const removeRegisterDevice = () => {
  if (!window.deviceToken) {
    return null;
  }

  return httpRequest.post(endpoints.logoutDevice + window.deviceToken);
};
