import { FC } from 'react';
import { IOrders } from 'models';
import { Typography } from '@mui/material';
import colors from 'styles/variables.scss';
import { deliveryMonthAndDayFormatter } from 'lib/utils';

import styles from './footer.module.scss';

type IFooter = Omit<IOrders.IOrder, 'items'> & { operationAllowed: boolean };

const Footer: FC<IFooter> = ({ operationAllowed, ...props }) => {
  return (
    <div className={styles.footerContainer}>
      <div>
        <Typography color={colors.black50} fontSize="0.8125rem">
          Delivery City
        </Typography>
        <Typography fontSize="1.125rem">
          {props.extension_attributes.delivery_city}
        </Typography>
      </div>

      <div>
        <Typography color={colors.black50} fontSize="0.8125rem">
          Delivery Date & Time
        </Typography>

        <Typography fontSize="1.125rem" color={colors.black50}>
          <strong>
            {deliveryMonthAndDayFormatter(
              props?.extension_attributes?.delivery_date
            )}
            ,{' '}
          </strong>
          {operationAllowed
            ? props?.extension_attributes?.delivery_time?.replace('_', ' - ')
            : props?.extension_attributes?.pickup_time?.replace('_', ' - ')}
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
